import React, { Component } from 'react';

import helpImg from '../../../assets/images/help.png';
import helpOImg from '../../../assets/images/help-o.png';


// css module
import styles from './Tooltip.module.scss';
import classNames from 'classnames/bind';

// Components
import Ballon from './Balloon';

const cx = classNames.bind(styles)

class Tooltip extends Component {

    state={
        is_show : false
    }

    hideDropdown = () => {
        this.setState({is_show : !this.state.is_show});
    }

    render() {

        const { state, props, hideDropdown } = this;
        const { children, text } = props;
        const { is_show } = state;

        return (
            <div className={cx("tooltip_box")}>
                <div className={cx("tooltip_btn")} onClick={hideDropdown}>
                    { children ? (children) : (
                        is_show ? (
                            <img src={helpImg} alt="" />
                        ) : (
                            <img src={helpOImg} alt="" />
                        )
                    ) }
                </div>
                {
                    is_show ? (
                        <Ballon
                            {...state}
                            hideDropdown={hideDropdown}
                            text={text}
                        />
                    ) : (null)
                }
                
            </div>
        );
    }
}

export default Tooltip;