import React, { Component } from 'react';

// Plugin
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// Action creator
import { openPopup } from '../reducers/dialogReducer';
import { fetchLogoutApi } from '../reducers/userReducer';

// Components
import Header from '../components/PageTemplate/Header';

class HeaderContainer extends Component {
  handleLogout = () => {
    const {
      loginUrl
     } = this.props.user
    this.props.fetchLogoutApi(this.props.user.token).then(() => {
      window.location.href = loginUrl;
    });
  };

  render() {
    const {
      handleLogout,
    } = this;
    const {
      user,
    } = this.props;

    return (
      <Header handleLogout={handleLogout} user={user} />
    );
  }
}

export default withRouter(connect(({ user, product }) => ({ user, product }), { fetchLogoutApi, openPopup })(HeaderContainer));
