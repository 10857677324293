import React, { Fragment } from 'react';

// CSS module
import classNames from 'classnames/bind';
import style from './CompanyInfoTab.module.scss';
const cx = classNames.bind(style);

const CompanyInfoTab = (
  maker_info,               // 메이커 정보
) => {
  if(!maker_info) {
    return;
  }

  const maker = maker_info.maker_info;

  const more_info = [];
  if(maker.moreInformation) {
    let mi = null;
    try {
      mi = JSON.parse(maker.moreInformation);
    } catch(e) {
    }
    if(mi) {
      let count = 0;
      for(let key in mi) {
        if(mi[key]) {
          if(count > 0) {
            let br_item = <br key={`${key}_br`}/>
            more_info.push(br_item);
          }
          count++;
          if(key.indexOf("WEB_SITE") > -1) {
            let item =
              <Fragment key={key}>
                <span className={cx('item')}>웹사이트 : </span>
                <a href={ mi[key].trim() } target='_blank' rel="noopener noreferrer">
                  <span className={cx('item', 'link')}>
                    { mi[key].trim() }
                  </span>
                </a>
              </Fragment>
            more_info.push(item);
          } else if(key.indexOf('FB') > -1) {
            let link = mi[key];
            if(mi[key].indexOf("https://www.facebook.com") === -1) {
              link = "https://www.facebook.com/" + mi[key];
            }
            let item =
              <Fragment key={key}>
                <span className={cx('item')}>페이스북 : </span>
                <a href={ link } target='_blank' rel="noopener noreferrer">
                  <span className={cx('item', 'link')}>
                    { mi[key].trim() }
                  </span>
                </a>
              </Fragment>
            more_info.push(item);
          } else if(key.indexOf('IG') > -1) {
            let item =
              <Fragment key={key}>
                <span className={cx('item')}>인스타그램 : </span>
                <a href={ mi[key].trim() } target='_blank' rel="noopener noreferrer">
                  <span className={cx('item', 'link')}>
                    { mi[key].trim() }
                  </span>
                </a>
              </Fragment>
            more_info.push(item);
          } else if(key.indexOf('TW') > -1) {
            let item =
              <Fragment key={key}>
                <span className={cx('item')}>트위터 : </span>
                <a href={ mi[key].trim() } target='_blank' rel="noopener noreferrer">
                  <span className={cx('item', 'link')}>
                    { mi[key].trim() }
                  </span>
                </a>
              </Fragment>
            more_info.push(item);
          } else if(key.indexOf('KAKAOPLUS_HOME') > -1) {
            let item =
              <Fragment key={key}>
                <span className={cx('item')}>카카오플러스 홈 : </span>
                <a href={ mi[key].trim() } target='_blank' rel="noopener noreferrer">
                  <span className={cx('item', 'link')}>
                    { mi[key].trim() }
                  </span>
                </a>
              </Fragment>
            more_info.push(item);
          } else if(key.indexOf('KAKAOPLUS_SEARCH') > -1) {
            let item =
              <Fragment key={key}>
                <span className={cx('item')}>카카오플러스ID : </span>
                  <span className={cx('item')}>
                    { mi[key].trim() }
                  </span>
              </Fragment>
            more_info.push(item);
          } else {
            let item =
              <Fragment key={key}>
                <span className={cx('item')}>기타 : </span>
                <a href={ mi[key].trim() } target='_blank' rel="noopener noreferrer">
                  <span className={cx('item', 'link')}>
                    { mi[key].trim() }
                  </span>
                </a>
              </Fragment>
            more_info.push(item);
          }
        }
      }
    }
  }

  // 사업자구분(개인,법인) - Mock API에서 corpType
  // IV : 개인 - 0 IB : 개인사업자 - 1 CB : 법인사업자 - 2
  let corpStr = "";
  if(maker.businessClassification === 'IV') {
    corpStr = "개인";
  } else if(maker.businessClassification === 'IB') {
    corpStr = "개인사업자";
  } else if(maker.businessClassification === 'CB') {
    corpStr = "법인사업자";
  }

  /*
  CORP("CORP", "주식회사"),
  LIMITED("LIMITED", "유한회사"),
  JOINTSTOCK("JOINTSTOCK", "합자회사"),
  PARTNERSHIP("PARTNERSHIP", "합명회사"),
  ETC("ETC", "기타");
  */

  let legalForm = "";
  if(maker.coperateType === 'CORP') {
    legalForm = "주식회사";
  } else if(maker.coperateType === 'LIMITED') {
    legalForm = "유한회사";
  } else if(maker.coperateType === 'JOINTSTOCK') {
    legalForm = "합자회사";
  } else if(maker.coperateType === 'PARTNERSHIP') {
    legalForm = "합명회사";
  } else if(maker.coperateType === 'ETC') {
    legalForm = "기타";
  }

  return (
    <div id={cx('company_info')}>
      <div className={cx('row1')}>
        <span className={cx('title')}>기업정보</span>
        <div className={cx('info_wrapper')}>
          <div className={cx('info_item')}>
            <span className={cx('info_title')}>기업명</span>
            <span className={cx('info_content')}>{ maker.makerName }</span>
          </div>
          <div className={cx('info_item', 'right')}>
            <span className={cx('info_title')}>법적 형태</span>
            <span className={cx('info_content')}>{ legalForm }</span>
          </div>
          <div className={cx('info_item')}>
            <span className={cx('info_title')}>사업자구분</span>
            <span className={cx('info_content')}>{ corpStr }</span>
          </div>
          <div className={cx('info_item', 'right')}>
            <span className={cx('info_title')}>업종명</span>
            <span className={cx('info_content')}>{ maker.businessName }</span>
          </div>
          <div className={cx('info_item')}>
            <span className={cx('info_title')}>사업자등록번호</span>
            <span className={cx('info_content')}>{ maker.registrationNumber }</span>
          </div>
          <div className={cx('info_item', 'right')}>
            <span className={cx('info_title')}>개업연월일</span>
            <span className={cx('info_content')}> { maker.openDate }</span>
          </div>
          <div className={cx('info_item')}>
            <span className={cx('info_title')}>법인등록번호</span>
            <span className={cx('info_content')}>{ maker.coperateRegistrationNumber }</span>
          </div>
          <div className={cx('info_item', 'right')}>
            <span className={cx('info_title')}>직원수</span>
            <span className={cx('info_content')}>{ maker.numberOfEmployees }</span>
          </div>
        </div>
      </div>
      <div className={cx('row2')}>
        <span className={cx('title')}>추가 정보</span>
        <div className={cx('more_info_wrapper')}>
          <p className={cx('sub_title')}>SNS</p>
          <p className={cx('content')}>
            { more_info }
          </p>
        </div>
      </div>
    </div>
  );
};

export default CompanyInfoTab;
