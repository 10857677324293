import React from 'react';

// CSS module
import classNames from 'classnames/bind';
import style from './UpdateContent.module.scss';
const cx = classNames.bind(style);

const UpdateContent = ({
  onChange,
  onApply,
  credibility_val,
  interactivity_val,
  attractiveness_val,
  bFlag,
}) => {
  return (
    <div className={cx('wrapper')}>
      <div className={cx('wz input', 'wrapper2')}>
        <span>평판</span> 
        <input type='text' name='credibility_val' value={credibility_val} placeholder='평판 점수' onChange={onChange}></input>
      </div>
      <div className={cx('wz input', 'wrapper2')}>
        <span>소통</span> 
        <input type='text' name='interactivity_val' value={interactivity_val} placeholder='소통 점수' onChange={onChange}></input>
      </div>
      <div className={cx('wz input', 'wrapper2')}>
        <span>인기</span> 
        <input type='text' name='attractiveness_val' value={attractiveness_val} placeholder='인기 점수' onChange={onChange}></input>
      </div>
      <button type='button' className={cx('wz button gray radius', 'btn_change')} onClick={(e) => onApply(e)} disabled={bFlag}>적용</button>
    </div>
  );
};

export default UpdateContent;
