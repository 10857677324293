/* eslint-disable no-prototype-builtins */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { openPopup, closePopup } from '../../../reducers/dialogReducer';
import className from 'classnames/bind';
import style from './LoaderDialog.module.scss';
import Loader from 'react-loader-spinner'

const cx = className.bind(style);

class LoaderDialog extends Component {
  initState = {
    isShow: false,
    width: 40,
    height: 40,
  };

  state = { ...this.initState };

  componentWillReceiveProps = nextProps => {
    if (nextProps.dialog.dialogType === 'loader') {
      if (nextProps.dialog.dialogData && nextProps.dialog.dialogData.isShow) {
        let width = nextProps.dialog.dialogData.width ? nextProps.dialog.dialogData.width : 40;
        let height = nextProps.dialog.dialogData.height ? nextProps.dialog.dialogData.widheightth : 40;
        this.setState({ 
          isShow: nextProps.dialog.dialogData.isShow,
          width: width,
          height: height,
        });
      } else {
        this.setState({ ...this.initState });
      }
    } else {
      this.setState({ ...this.initState });
    }
  };

  componentWillUnmount = () => {
    let body = document.getElementsByTagName('body')[0];
    body.style.overflowY = 'auto';
  }

  render() {
    const {
      isShow,
      width,
      height,
    } = this.state;

    return isShow ? (
      <div className={cx('shadow')}>
        <div className={cx('wrapper')}>
          <Loader
            type="Puff"
            color="#65bfce"
            height={height}
            width={width}
          />
        </div>
      </div>
    ) : null;
  }
}

export default connect(({ dialog }) => ({ dialog }), ({ openPopup, closePopup }))(LoaderDialog);
