import React from 'react';

// Plugin
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
// Components
import PageTemplate from './components/PageTemplate';
// Pages
import MakerPage from './pages/MakerPage';
import SettingPage from './pages/SettingPage';
import HistoryPage from './pages/HistoryPage';
import UpdatePage from './pages/UpdatePage';
// Dialog
import Dialog from './containers/dialogs/Dialog';
// Librarys
import queryString from 'query-string';
// Reducer
import { fetchLoginApi } from './reducers/userReducer';

const RootRouter = ({
  component: Component, footer, isAuthed, tmpFetchLoginApi, ...rest
}) => {
  const parsed = queryString.parse(window.location.search);
  if(parsed.JSESSIONID && parsed.loginUrl) {
    // 로그인하고 넘어온 상태
    tmpFetchLoginApi(parsed.JSESSIONID, parsed.loginUrl)
    .then(res => {
      if(parsed.projectId && parsed.projectType) {
        window.location.href = '/wtp/maker?projectId=' + parsed.projectId + '&projectType=' + parsed.projectType;
      } else {
        window.location.href = '/wtp/maker';
      }
    });
    return (
      <div></div>
    );
  } else {
    if(isAuthed) {
      // 로그인된 상태
      return (
        <Route
          {...rest}
          render = {props => (
            <Redirect to={{ pathname: '/wtp/maker', state: {from: props.location }}} />
          )}
        />
      );
    } else {
      // 로그인 안된 상태
      alert('권한이 없습니다. 권한이 있는 계정으로 로그인 해주세요');
      if(parsed.loginUrl) {
        window.location.href = parsed.loginUrl;
      } else {
        return (
          <div></div>
        );
      }
    }
  }
};

const PrivateRoute = ({
  component: Component, isAuthed, type, ...rest
}) => {
  const parsed = queryString.parse(window.location.search);
  if(parsed.projectId && parsed.projectType) {
    if(window.location.pathname === '/wtp/maker') {
      return (
        <Route
          { ...rest }
          render={props => (isAuthed && (
            <PageTemplate>
              <Component
                projectId={parsed.projectId}
                projectType={parsed.projectType}
               { ...props } />
            </PageTemplate>
          ))
          }
        />
      );
    } else {
      return (
        <Route
          { ...rest }
          render={props => (
            <Component 
              JSESSIONID={parsed.JSESSIONID}
              projectId={parsed.projectId}
              projectType={parsed.projectType}
              { ...props }
            />
          )}
        />
      );
    }
  } else {
    if(!isAuthed) {
      alert('권한이 없습니다. 권한이 있는 계정으로 로그인 해주세요');
    } else {
      return (
        <Route
          { ...rest }
          render={props => (isAuthed && (
            <PageTemplate>
              <Component { ...props } type={type}/>
            </PageTemplate>
          ))
          }
        />
      );
    }
  }
};

const App = ({ user, fetchLoginApi: tmpFetchLoginApi }) => {
  return (
    <>
      <Router>
        {
          <Switch>
            <RootRouter exact path="/" isAuthed={user.isAuthed} tmpFetchLoginApi={tmpFetchLoginApi} />
            <PrivateRoute path="/wtp/maker" component={MakerPage} isAuthed={user.isAuthed} />
            <PrivateRoute path="/wtp/setting_funding" component={SettingPage} isAuthed={user.isAuthed} type="FUNDING"/>
            <PrivateRoute path="/wtp/setting_equity" component={SettingPage} isAuthed={user.isAuthed} type="EQUITY"/>
            <PrivateRoute path="/wtp/history_funding" component={HistoryPage} isAuthed={user.isAuthed} type="FUNDING"/>
            <PrivateRoute path="/wtp/history_equity" component={HistoryPage} isAuthed={user.isAuthed} type="EQUITY"/>
            <PrivateRoute path="/wtp/update" component={UpdatePage} isAuthed={user.isAuthed} />
          </Switch>
        }
      </Router>
      <Dialog />
    </>
  );
};

export default connect(({ user }) => ({ user }), ({ fetchLoginApi }))(App);
