import React from 'react';

// Plugin
import { NavLink } from 'react-router-dom';

// icons
import adAdminLogo from '../../../assets/images/g-logo.png';

// CSS module
import className from 'classnames/bind';
import style from './Header.module.scss';
const cx = className.bind(style);

const Header = ({ handleLogout, user }) => {
  let isAuthed = false;
  if (user) {
    isAuthed = user.isAuthed;
  }
  return (
    <header id={cx('header')}>
      <div className={cx('left')}>
        <NavLink to={'/'}>
          <div className={cx('logo')}>
            <img alt="와디즈 ad logo" data-type="image" src={adAdminLogo} />
          </div>
        </NavLink>
        <div className={cx('rectangle')}></div>
        <div>
          <span className={cx('headername')}>신뢰지수</span>
        </div>
      </div>
      <div className={cx('right')}>
        {isAuthed && (
          <>
            <button className={cx('my_page')} onClick={handleLogout}>wadiz admin으로 돌아가기</button>
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
