import React, { Component } from 'react';

// Plugin
import { withRouter } from 'react-router-dom';

// Components
import LeftMenu from '../components/PageTemplate/LeftMenu';


class LeftMenuContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuList: [
        {
          name: '신뢰지수',
          id: 'wtp',
          children: [
            { name: '메이커별 신뢰지수', link: '/wtp/maker' },
            { name: '신뢰지수 설정', children: [
              { name: "펀딩", link:'/wtp/setting_funding' },
              { name: "투자", link:'/wtp/setting_equity' }
            ] },
            { name: '신뢰지수 설정 히스토리', children: [
              { name: "펀딩", link:'/wtp/history_funding' },
              { name: "투자", link:'/wtp/history_equity' }
            ] },
          ],
          isExpand: true,
        },
      ],
    };
  }

  handleExpand = idx => {
    if (this.state.menuList[idx].hasOwnProperty('link')) {
      this.props.history.push(this.state.menuList[idx].link);
    } else {
      this.setState(({ menuList }) => {
        const tmpList = Object.assign([], menuList);
        tmpList[idx].isExpand = !tmpList[idx].isExpand;

        return ({ menuList: tmpList });
      });
    }
  };

  render() {
    const { state, handleExpand } = this;
    return (
      <LeftMenu {...state} handleExpand={handleExpand} />
    );
  }
}

export default withRouter(LeftMenuContainer);
