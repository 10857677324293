import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../reducers/rootReducer';
import storage from 'redux-persist/lib/storage';

export default function configureStore() {
  const composeEnhancer = (
    process.env.REACT_APP_ENVIRONMENT !== 'live'
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ) || compose;

  const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['dialog'],
  };

  const enhancerReducer = persistReducer(persistConfig, rootReducer);

  const store = createStore(
    enhancerReducer,
    composeEnhancer(
      applyMiddleware(
        thunkMiddleware,
      ),
    ),
  );

  const persistor = persistStore(store);

  return { store, persistor };
}
