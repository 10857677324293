import React from 'react';

// Components
import HeaderContainer from '../../containers/HeaderContainer';
import LeftMenuContainer from '../../containers/LeftMenuContainer';

// CSS module
import classNames from 'classnames/bind';
import style from './PageTemplate.module.scss';
const cx = classNames.bind(style);

const PageTemplate = ({ children }) => {
  return (
    <div id={cx('main')}>
      <HeaderContainer />
      <div id={cx('content_wrap')}>
        <LeftMenuContainer />
        <div id={cx('contentMain')}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default PageTemplate;
