import React from 'react';

import { Table } from 'react-bootstrap';

// CSS module
import classNames from 'classnames/bind';
import style from './HistoryTable.module.scss';
const cx = classNames.bind(style);

const HistoryTable = ({
  history,                  // 히스토리
}) => {
  const hist = history;
  let tbody_rows = [];

  let credFlag = false, interFlag = false, attrFlag = false;

  let prev_auto_row = null;
  for(let i=hist.length - 1;i>=0;i--) {
    let status = '';
    /*
    IN_SCREENING  // 심사 중
    , COMINGSOON // 오픈예정
    , IN_PROGRESS // 진행중
    , FINISHED // 종료
    */
    if(hist[i].status === 'IN_SCREENING') {
      status = '심사중';
    } else if(hist[i].status === 'COMINGSOON') {
      status = '오픈예정';
    } else if(hist[i].status === 'IN_PROGRESS') {
      status = "펀딩중";
    } else if(hist[i].status === 'FINISHED') {
      status = '펀딩 종료';
    }
    let credVal, interVal, attrVal;
    let adminFlag = false;
    let updated = hist[i].updatedAt.split(' ');

    if(hist[i].latestPerformer !== 'auto' && hist[i].latestPerformer != null) {
      adminFlag = true;
    }

    if(i === hist.length - 1) {
      credVal = hist[i].credibilityResult;
      interVal = hist[i].interactivityResult;
      attrVal = hist[i].attractivenessResult;
    } else {
      if(adminFlag) {
        credVal = hist[i].credibilityResult;
        interVal = hist[i].interactivityResult;
        attrVal = hist[i].attractivenessResult;
      } else {
        credVal = credFlag ? '-' : hist[i].credibilityResult;
        interVal = interFlag ? '-' : hist[i].interactivityResult;
        attrVal = attrFlag ? '-' : hist[i].attractivenessResult;
      }
    }

    if(adminFlag) {
      if(prev_auto_row) {
        if(prev_auto_row.credibilityResult !== hist[i].credibilityResult) {
          credFlag = true;
        }
        if(prev_auto_row.interactivityResult !== hist[i].interactivityResult) {
          interFlag = true;
        }
        if(prev_auto_row.attractivenessResult !== hist[i].attractivenessResult) {
          attrFlag = true;
        }
      }
    }

    if(adminFlag) {
    } else {
      prev_auto_row = hist[i];
    }


    const item =
      <tr key={`${i}`} className={cx( adminFlag && 'highlight' )}>
        <td className={cx('date')}><span className={cx('date_for')}>{ updated[0].substring(2) } <em>{updated[1]}</em></span></td>
        <td className={cx('left_border')}>{ status }</td>
        <td className={cx('left_border')}>
        {
          credVal === 999
          ? 'N/A'
          : credVal
        }
        </td>
        <td>{ hist[i].creditRank ? hist[i].creditRank : '-' }</td>
        <td>{ hist[i].previousCampaignCredibility != null ? hist[i].previousCampaignCredibility : '-'}</td>
        <td>{ hist[i].declareProject != null ? hist[i].declareProject : '-'}</td>
        <td>{ hist[i].encoreRequest != null ? hist[i].encoreRequest : '-'}</td>
        <td>{ hist[i].satisfactionEvaluation != null ? hist[i].satisfactionEvaluation : '-'}</td>
        <td className={cx('left_border')}>
        {
          interVal === 999
          ? 'N/A'
          : interVal
        }
        </td>
        <td>{ hist[i].cheerOpinionResponseRatio != null ? hist[i].cheerOpinionResponseRatio : '-'} </td>
        <td>{ hist[i].cheerOpinionResponseTime != null ? hist[i].cheerOpinionResponseTime : '-'}</td>
        <td>{ hist[i].newsCount != null ? hist[i].newsCount : '-'}</td>
        <td>{ hist[i].newsResponseRatio != null ? hist[i].newsResponseRatio : '-'}</td>
        <td>{ hist[i].newsResponseTime != null ? hist[i].newsResponseTime : '-'}</td>
        <td>{ hist[i].faqResponseTime != null ? hist[i].faqResponseTime : '-'}</td>
        <td className={cx('left_border')}>
        {
          attrVal === 999
          ? 'N/A'
          : attrVal
        }
        </td>
        <td>{ hist[i].numberOfLike != null ? hist[i].numberOfLike : '-'}</td>
        <td>{ hist[i].numberOfCheer != null? hist[i].numberOfCheer : '-'}</td>
        <td>{ hist[i].numberOfSupproting != null ? hist[i].numberOfSupproting : '-'}</td>
        <td className={cx('left_border')}>{ hist[i].latestPerformer ? hist[i].latestPerformer : 'auto' }</td>
      </tr>;
    tbody_rows.unshift(item);
  }
  return (
    <Table striped className={ cx('table') }>
      <thead>
        <tr>
        <th rowSpan='2' className={cx('top_thead_date')}>업데이트 날짜</th>
        <th rowSpan='2' className={cx('top_thead')}>상태</th>
        <th colSpan='6' className={cx('top_thead', 'cred')}>평판</th>
        <th colSpan='7' className={cx('top_thead', 'inter')}>소통</th>
        <th colSpan='4' className={cx('top_thead', 'attr')}>인기</th>
        <th rowSpan='2' className={cx('top_thead', 'latest')}>최종 업데이트 진행자</th>
      </tr>
      <tr>
        <th className={cx('left_border')}>지수</th>
        <th>신용등급</th>
        <th>이전 평판지수 평균</th>
        <th>프로젝트 신고하기</th>
        <th>앵콜펀딩 요청</th>
        <th>만족도 평가</th>
        <th className={cx('left_border')}>지수</th>
        <th>의견 응답 비율</th>
        <th>의견 반응 시간</th>
        <th>새소식 수</th>
        <th>새소식 댓글 응답 비율</th>
        <th>새소식 댓글 반응 시간</th>
        <th>1:1문의 반응 시간</th>
        <th className={cx('left_border')}>지수</th>
        <th>좋아요 수</th>
        <th>응원 수</th>
        <th>지지서명 수</th>
        </tr>
      </thead>
      <tbody>
        { tbody_rows.length === 0 ? (
          <tr>
            <td colSpan='20' style={{ height: '200px '}}>등록된 컨텐츠가 없습니다.</td>
          </tr>
        ) : (
          tbody_rows
        )}
      </tbody>
    </Table>
  );
};

export default HistoryTable;
