import React from 'react';

// CSS module
import classNames from 'classnames/bind';
import style from './PageLocation.module.scss';
const cx = classNames.bind(style);

const PageLocation = ({ locArr }) => {
  const locListRender = (locs) => (
    locs.map((item, index) => {
      if (index !== 0) {
        return (
          <li key={index} className={cx(item.active ? 'active' : '')}>
            <i className='icon chevron-right'></i>
            {item.loc}
          </li>
        );
      }
      return (
        <li key={index} className={cx(item.active ? 'active' : '')}>{item.loc}</li>
      );
    })
  );

  return (
    <ul className={cx('page_location')}>
      { locListRender(locArr) }
    </ul>
  );
};

export default PageLocation;
