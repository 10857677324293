import React from 'react';
import PropTypes from 'prop-types';
import className from 'classnames/bind';
import style from './ToggleBtn.module.scss';
const cx = className.bind(style);

const ToggleBtn = ({
  val,
  idx,
  isUse,
  onChangeIsUse,
}) => {
  return (
    <>
      <label className={ cx('toggle') }>
        <input type='checkbox' checked={val ? val.isTrue : isUse} onChange={ () => { if (val) onChangeIsUse(val, idx); else onChangeIsUse(); } } />
        <span></span>
      </label>
    </>
  );
};

ToggleBtn.propTypes = {
  val: PropTypes.object,
  idx: PropTypes.number,
};

export default ToggleBtn;
