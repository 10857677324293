import React, { Component } from 'react';
import { connect } from 'react-redux';
import { openPopup, closePopup } from '../../../reducers/dialogReducer';
import className from 'classnames/bind';
import commonStyle from './common.module.scss';
const ccx = className.bind(commonStyle);

class AlertDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShow: false,
    };
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.dialog.dialogType === 'alert') this.setState({ isShow: true });
    else this.setState({ isShow: false });
  };

  closePopup = () => {
    const { dialog } = this.props;
    const { dialogData } = dialog;

    this.props.closePopup();
    if(dialogData.hasOwnProperty('closeCallback')) {
      dialogData.closeCallback();
    }
  }

  render() {
    const { isShow } = this.state;
    const { dialog } = this.props;
    const { closePopup } = this;


    return isShow ? (
      <div className={ccx('shadow')}>
        <div style={{ minWidth: '375px', padding: '43px 24px 29px 24px' }} className={ccx('dialog_wrap')}>
          <div className={ccx('dialog_content')}>
            <span>{dialog.dialogData.content}</span>
          </div>
          <div style={{ minWidth: '375px', padding: '0' }} className={ccx('dialog_button')}>
            <button type='button' className="wz button" onClick={closePopup}>닫기</button>
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default connect(({ dialog }) => ({ dialog }), ({ openPopup, closePopup }))(AlertDialog);
