import React, { PureComponent } from 'react';

// Plugin
import { connect } from 'react-redux';

// Components, Dialog
import UpdateContent from '../components/UpdateContent';
import { openPopup, closePopup } from '../reducers/dialogReducer';
import { fetchLogoutApi } from '../reducers/userReducer';

class UpdatePage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      credibility_val: 0,
      interactivity_val : 0,
      attractiveness_val: 0,
      projectInfo: null,
      bFlag: true,
    };
  }

  componentDidMount = () => {
    this.getWtp();
  }

  getWtp = () => {
    const {
      JSESSIONID,
      projectId,
      projectType,
    } = this.props;

    this.props.openPopup({
      dialogType: 'loader',
      dialogData: {
        isShow: true,
      }
    });

    fetch(`${process.env.REACT_APP_RELAY_API_HOST}api/v1/wtp/showProject?projectId=${projectId}&projectType=${projectType}` , {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'JSESSIONID': JSESSIONID,
      }
    })
    .then(response => {
      if(response.status === 401) {
        alert('권한이 없습니다. 권한이 있는 계정으로 로그인 해주세요');
        const {
          loginUrl
         } = this.props.user
        this.props.fetchLogoutApi(this.props.user.token).then(() => {
          window.location.href = loginUrl;
        });
      } else {
        return response.json()
      }
    })
    .then(response => {
      if(response.result) {
        this.setState({
          projectInfo: response.response,
          credibility_val: response.response.credibilityResult,
          interactivity_val : response.response.interactivityResult,
          attractiveness_val: response.response.attractivenessResult,
        }, () => {
          this.props.closePopup();
        });
      } else {
        alert('신뢰지수 계산값이 존재하지 않습니다. 나중에 다시 확인해 주세요');
        window.close();
      }
    }); 
  }

  onChange = (e) => {
    const name = e.target.name;
    const value = parseInt(e.target.value);
    if(e.target.value !== "" && e.target.value !== '-' && isNaN(value)) {
      return;
    }
    let vv = e.target.value.replace(/[^0-9.]/g, '');
    if(vv !== e.target.value) {
      return;
    }

    let bFlag = true; 
    if(name === "credibility_val") {
      if(value !== this.state.projectInfo.credibilityResult) {
        bFlag = false;
      }
    } else if(name === 'interactivity_val') {
      if(value !== this.state.projectInfo.interactivityResult) {
        bFlag = false;
      }
    } else if(name === 'attractiveness_val') {
      if(value !== this.state.projectInfo.attractivenessResult) {
        bFlag = false;
      }
    }
    this.setState({
      [name]: e.target.value,
      bFlag: bFlag,
    });
  }

  onApply = () => {
    const {
      credibility_val,
      interactivity_val,
      attractiveness_val,
    } = this.state;

    const {
      JSESSIONID,
      projectId,
      projectType,
    } = this.props;

    if(credibility_val > 1 || credibility_val < -1 || isNaN(parseInt(credibility_val))) {
      // alert
      alert('평판지표는 -1 ~ 1의 값이어야 합니다.');
      document.querySelector("input[name=credibility_val]").focus();
      return;
    } else if(interactivity_val > 1 || interactivity_val < -1 || isNaN(parseInt(interactivity_val))) {
      alert('소통지표는 -1 ~ 1의 값이어야 합니다.');
      document.querySelector("input[name=interactivity_val]").focus();
      return;
    } else if(attractiveness_val > 1 || attractiveness_val < -1 || isNaN(parseInt(attractiveness_val))) {
      alert('인기지표는 -1 ~ 1의 값이어야 합니다.');
      document.querySelector("input[name=attractiveness_val]").focus();
      return;
    } else {
      this.props.openPopup({
        dialogType: 'loader',
        dialogData: {
          isShow: true,
        }
      });
      fetch(`${process.env.REACT_APP_RELAY_API_HOST}api/v1/wtp/updateScore` , {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'JSESSIONID': JSESSIONID,
        },
        body: JSON.stringify({
          makerId: this.state.projectInfo.makerId,
          projectId: projectId, 
          projectType: projectType,
          credibilityResult: credibility_val,
          interactivityResult: interactivity_val,
          attractivenessResult: attractiveness_val,
          latestPerformer: this.props.user.id,
        })
      })
      .then(response => {
        if(response.status === 401) {
          alert('권한이 없습니다. 권한이 있는 계정으로 로그인 해주세요');
          const {
            loginUrl
           } = this.props.user
          this.props.fetchLogoutApi(this.props.user.token).then(() => {
            window.location.href = loginUrl;
          });
        } else {
          return response.json()
        }
      })
      .then(response => {
        if(response.result) {
          alert('수정이 완료되었습니다.');
          this.props.closePopup();
          window.close();
        }
      }); 
    }
  }

  render() {
    const {
      credibility_val,
      interactivity_val,
      attractiveness_val,
      bFlag,
    } = this.state;

    const {
      onChange,
      onApply,
    } = this;

    return (
      <UpdateContent
        onChange={onChange}
        onApply={onApply}
        credibility_val={credibility_val}
        interactivity_val={interactivity_val}
        attractiveness_val={attractiveness_val}
        bFlag={bFlag}
      />
    );
  }
}

export default connect(({ user }) => ({ user }), ({ fetchLogoutApi,  openPopup, closePopup  }))(UpdatePage);
