import React from 'react';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import styled from 'styled-components';

const DateWrapper = styled.div`
  .SingleDatePickerInput {
    border-radius: 0px;
    border: 0px;
  }
  .DateInput {
    position: relative;
    font-size: 15px;
    width: auto;
  }
  .DateInput_input{
    width: 200px !important;
    height: 32px;
    line-height: 32px;
    font-size: inherit;
    padding-right: 2.35294em;
    font-weight: 300;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: #fff;
    padding: 0 0.94118em;
    width: 100%;
    vertical-align: middle;
    box-sizing: border-box;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
    transition-property: border-color, height, padding-right, color;
    transition-duration: 0.2s, 0s;
    &:hover, &:focus{
      border-color: #00c4c4;
    }
  }
  .SingleDatePickerInput_calendarIcon{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-51%);
    transition-property: transform, opacity, color;
    transition-duration: 0.2s;
    padding-right: 0.33333em;
    padding-left: 0.33333em;
    width: auto;
    height: auto;
    color: rgba(0, 0, 0, 0.3);
    font-size: 1.41176em;
    pointer-events: none;
  }
`;

const CustomDatePicker = ({
  onDateChange,
  onFocusChange,
  focused: focus,
  date,
  placeholder,
  disabled,
  id
}) => {
  return (
    <DateWrapper>
      <SingleDatePicker
        // numberOfMonths={1}
        onDateChange={d => { onDateChange(d); }}
        onFocusChange={({ focused }) => { onFocusChange({ focused }); }}
        focused={focus}
        date={date}
        inputIconPosition="after"
        isOutsideRange={() => false}
        customInputIcon={<i className="calendar-o icon"></i>}
        placeholder={placeholder}
        disabled={disabled}
        id={id}
      />
    </DateWrapper>
  );
};

export default CustomDatePicker;
