import React from 'react';

import HistoryTable from './HistoryTable';

// CSS module
import classNames from 'classnames/bind';
import style from './ProjectInfoTab.module.scss';
const cx = classNames.bind(style);

const ProjectInfoTab = ({
  project_info,           // 프로젝트 정보
  credibility_val,
  interactivity_val,
  attractiveness_val,
  bIndex,
  handleChangeResult,     // 지수 정보 변경 handler
  onChange,
  admin_eval,
  onChangeAdmin,
  bIndex2,
  handleChangeAdminEval,
  indexClick,
}) => {
  if(!project_info) {
    return;
  }
  let projectStatus = '';
  if(project_info.projectStatus2 === "IN_PROGRESS") {
    projectStatus = '펀딩중';
  } else if(project_info.projectStatus2 === "COMINGSOON") {
    projectStatus = '오픈예정';
  } else if(project_info.projectStatus2 === "FINISHED") {
    projectStatus = '펀딩 종료';
  } else if(project_info.projectStatus2 === "IN_SCREENING") {
    projectStatus = '심사중';
  } else if(project_info.projectStatus2 === "DELETED") {
    projectStatus = '삭제';
  }

  return (
    <div id={cx('project_info')}>
      <div className={cx('row1')}>
        <span className={cx('title')}>프로젝트 정보</span>
        <div className={cx('info_wrapper')}>
          <div className={cx('info_item')}>
            <span className={cx('info_title')}>프로젝트 ID</span>
            <span className={cx('info_content')}>{ project_info.projectId }</span>
          </div>
          <div className={cx('info_item', 'right')}>
            <span className={cx('info_title')}>프로젝트 상태</span>
            <span className={cx('info_content')}>{ projectStatus }</span>
          </div>
          <div className={cx('info_item')}>
            <span className={cx('info_title')}>프로젝트 명</span>
            <span className={cx('info_content', 'ellipse')}>{ project_info.projectName }</span>
          </div>
          <div className={cx('info_item', 'right')}>
            <span className={cx('info_title')}>프로젝트 타입</span>
            <span className={cx('info_content')}>{ project_info.projectType2 === 'EQUITY' ? '투자' : '펀딩' }</span>
          </div>
        </div>
      </div>
      <div className={cx('row1')}>
        <span className={cx('title')}>메이커 신뢰지수</span>
        <div className={cx('info_wrapper')}>
          <div className={cx('info_item')}>
            <span className={cx('info_title')}>이전 프로젝트 명</span>
            <span className={cx('info_content')}>{ project_info.prevProject ? project_info.prevProject.projectName : '없음' }</span>
          </div>
          <div className={cx('info_item', 'right')}>
            <span className={cx('info_title')}>현재 프로젝트</span>
            {/* <span className={cx('info_content')}>{ projectStatus }</span> */}
          </div>
          <div className={cx('info_item')} style={{height: "60px"}}>
            <span className={cx('info_title')}>신뢰지수</span>
            <div className={cx('wrapper')}>
              <div className={cx('wrapper2')}>
                <span>평판</span>
                {
                  (() => {
                    if (project_info.prevProject) {
                      switch (project_info.prevProject.credibilityResult) {
                        case -1: return <div className={cx('rect_back')}><span className={cx('low')}></span></div>
                        case 0: return <div className={cx('rect_back')}><span className={cx('mid')}></span></div>
                        case 1: return <div className={cx('rect_back')}><span className={cx('high')}></span></div>
                        default: return <span>N/A</span>;
                      }
                    } else {
                      return <span>N/A</span>;
                    }
                  })()
                }
              </div>
              <div className={cx('wrapper2')}>
                <span>소통</span>
                {
                  (() => {
                    if (project_info.prevProject) {
                      switch (project_info.prevProject.interactivityResult) {
                        case -1: return <div className={cx('rect_back')}><span className={cx('low')}></span></div>
                        case 0: return <div className={cx('rect_back')}><span className={cx('mid')}></span></div>
                        case 1: return <div className={cx('rect_back')}><span className={cx('high')}></span></div>
                        default: return <span>N/A</span>;
                      }
                    } else {
                      return <span>N/A</span>;
                    }
                  })()
                }
              </div>
              <div className={cx('wrapper2')}>
                <span>인기</span>
                {
                  (() => {
                    if (project_info.prevProject) {
                      switch (project_info.prevProject.attractivenessResult) {
                        case -1: return <div className={cx('rect_back')}><span className={cx('low')}></span></div>
                        case 0: return <div className={cx('rect_back')}><span className={cx('mid')}></span></div>
                        case 1: return <div className={cx('rect_back')}><span className={cx('high')}></span></div>
                        default: return <span>N/A</span>;
                      }
                    } else {
                      return <span>N/A</span>;
                    }
                  })()
                }
              </div>
            </div>
          </div>
          <div className={cx('info_item', 'right')} style={{height: "60px"}}>
            <span className={cx('info_title')}>신뢰지수</span>
            <div className={cx('wrapper')}>
              <div className={cx('wrapper2', 'editable')} onClick={(e) => { (credibility_val !== 999) && indexClick('credibility_val') }}>
                <span>평판</span>
                {
                  credibility_val === -1 ?
                    <div className={cx('rect_back')}><span className={cx('low')}></span></div>
                  : credibility_val === 0 ?
                    <div className={cx('rect_back')}><span className={cx('mid')}></span></div>
                  : credibility_val === 1 ?
                    <div className={cx('rect_back')}><span className={cx('high')}></span></div>
                  :
                    <div>N/A</div>
                }
              </div>
              <div className={cx('wrapper2', (project_info.projectStatus !== 5 ? 'editable' : '' ))} onClick={(e) => { (project_info.projectStatus !==5 && attractiveness_val !== 999) && indexClick('interactivity_val') }}>
                <span>소통</span>
                {
                  interactivity_val === -1 ?
                    <div className={cx('rect_back')}><span className={cx('low')}></span></div>
                  : interactivity_val === 0 ?
                    <div className={cx('rect_back')}><span className={cx('mid')}></span></div>
                  : interactivity_val === 1 ?
                    <div className={cx('rect_back')}><span className={cx('high')}></span></div>
                  :
                    <div>N/A</div>
                }
              </div>
              <div className={cx('wrapper2', (project_info.projectStatus !== 5 ? 'editable' : '' ))} onClick={(e) => { (project_info.projectStatus !==5 && attractiveness_val !== 999) && indexClick('attractiveness_val') }}>
                <span>인기</span>
                {
                  attractiveness_val === -1 ?
                    <div className={cx('rect_back')}><span className={cx('low')}></span></div>
                  : attractiveness_val === 0 ?
                    <div className={cx('rect_back')}><span className={cx('mid')}></span></div>
                  : attractiveness_val === 1 ?
                    <div className={cx('rect_back')}><span className={cx('high')}></span></div>
                  :
                    <div>N/A</div>
                }
              </div>
              <button type='button' className={cx('wz button gray radius', 'btn_change')} onClick={(e) => handleChangeResult(e)} disabled={!bIndex}>적용</button>
            </div>
          </div>
        </div>
      </div>
      <div className={cx('row3')}>
        <span className={cx('title')}>히스토리</span>
        <div className={cx('wrapper')}>
          <HistoryTable
            history={project_info.history}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectInfoTab;
