import React from 'react';

// CSS module
import className from 'classnames/bind';
import style from './Paginator.module.scss';
const cx = className.bind(style);

const Paginator = ({
  data, onChangePage, onChangeCurrPage, ...rest
}) => {
  return (
    <ul className={ cx('pagination')} {...rest}>
      <li>
        <button
          className={ cx('pagination_arrow', { active: data.activePageIdx !== 0 }) }
          disabled={data.activePageIdx === 0}
          onClick={() => onChangePage('prev')}
        >
          <i className='icon chevron-left' />
        </button>
      </li>
      {data.activePageIdx !== 0 && (
        <>
          <li><button className={ cx('pagination_item')} onClick={() => onChangeCurrPage(data.totalArr[0][0])}>{data.totalArr[0][0]}</button></li>
          <li><button className={ cx('pagination_item')}>...</button></li>
        </>
      )}
      {
        data.items.map((val, idx) => (
          <li key={idx}><button className={ cx('pagination_item', { active: data.activeIdx === idx })} onClick={() => onChangeCurrPage(val)}>{val}</button></li>
        ))
      }
      {data.activePageIdx !== data.totalArr.length - 1 && (
        <>
          <li><button className={ cx('pagination_item')}>...</button></li>
          <li><button className={ cx('pagination_item')} onClick={() => onChangeCurrPage(data.totalArr[data.totalArr.length - 1].slice(-1))}>{data.totalArr[data.totalArr.length - 1].slice(-1)}</button></li>
        </>
      )}
      <li>
        <button
          className={ cx('pagination_arrow', { active: data.activePageIdx !== data.totalArr.length - 1 }) }
          disabled={data.activePageIdx === data.totalArr.length - 1}
          onClick={() => onChangePage('next')}
        >
          <i className='icon chevron-right' />
        </button>
      </li>
    </ul>
  );
};

export default Paginator;
