import React from 'react';

// Plugin
import { Table } from 'react-bootstrap';

// Components
import PageLocation from '../PageLocation';
import PaginatorContainer from '../../containers/PaginatorContainer';

// CSS module
import classNames from 'classnames/bind';
import style from './HistoryContent.module.scss';
const cx = classNames.bind(style);


const countColSpan = (setting) => {
  let count = 0;
  for(let i=0;i<setting.length;i++) {
    count += 3;
    for(let j=0;j<setting[i].factorSettingList.length;j++) {
      count += 5;
    }
  }
  return count;
};

const getRow2Item = (setting) => {
  let retVal = [];
  const cred = setting.credibilityPointSetting;
  const int = setting.interactivityPointSetting;
  const attr = setting.attractivenessPointSetting;

  for(let i=0;i<cred.length;i++) {
    let item = cred[i];
    let status = "";
    if(item.campaignStatus === "IN_SCREENING") {
      status = "심사중";
    } else if(item.campaignStatus === "IN_PROGRESS") {
      status = "펀딩중";
    } else if(item.campaignStatus === "FINISHED") {
      status = "펀딩 종료";
    }
    let count = 3 + 5 * item.factorSettingList.length;
    let it = {
      "status": status,
      "span": count
    };
    retVal.push(it);
  }
  for(let i=0;i<int.length;i++) {
    let item = int[i];
    let status = "";
    if(item.campaignStatus === "IN_SCREENING") {
      status = "심사중";
    } else if(item.campaignStatus === "IN_PROGRESS") {
      status = "펀딩중";
    } else if(item.campaignStatus === "FINISHED") {
      status = "펀딩 종료";
    }
    let count = 3 + 5 * item.factorSettingList.length;
    let it = {
      "status": status,
      "span": count
    };
    retVal.push(it);
  }
  for(let i=0;i<attr.length;i++) {
    let item = attr[i];
    let status = "";
    if(item.campaignStatus === "IN_SCREENING") {
      status = "심사중";
    } else if(item.campaignStatus === "IN_PROGRESS") {
      status = "펀딩중";
    } else if(item.campaignStatus === "FINISHED") {
      status = "펀딩 종료";
    }
    let count = 3 + 5 * item.factorSettingList.length;
    let it = {
      "status": status,
      "span": count
    };
    retVal.push(it);
  }
  return retVal;
};

const getRow3Item = (setting) => {
  let retVal = [];
  const cred = setting.credibilityPointSetting;
  const int = setting.interactivityPointSetting;
  const attr = setting.attractivenessPointSetting;

  for(let i=0;i<cred.length;i++) {
    retVal.push({"spanType":"row", "span":2, "text":"시간 처리 유형"});
    retVal.push({"spanType":"row", "span":2, "text":"타임 윈도우"});
    retVal.push({"spanType":"row", "span":2, "text":"건너 뛸 시간"});
    const factor = cred[i].factorSettingList;
    for(let j=0;j<factor.length;j++) {
      retVal.push({"spanType":"col", "span":5, "text":factor[j].name});
    }
  }
  for(let i=0;i<int.length;i++) {
    retVal.push({"spanType":"row", "span":2, "text":"시간 처리 유형"});
    retVal.push({"spanType":"row", "span":2, "text":"타임 윈도우"});
    retVal.push({"spanType":"row", "span":2, "text":"건너 뛸 시간"});
    const factor = int[i].factorSettingList;
    for(let j=0;j<factor.length;j++) {
      retVal.push({"spanType":"col", "span":5, "text":factor[j].name});
    }
  }
  for(let i=0;i<attr.length;i++) {
    retVal.push({"spanType":"row", "span":2, "text":"시간 처리 유형"});
    retVal.push({"spanType":"row", "span":2, "text":"타임 윈도우"});
    retVal.push({"spanType":"row", "span":2, "text":"건너 뛸 시간"});
    const factor = attr[i].factorSettingList;
    for(let j=0;j<factor.length;j++) {
      retVal.push({"spanType":"col", "span":5, "text":factor[j].name});
    }
  }

  return retVal;
};

const getRow4Item = (setting) => {
  let retVal = [];
  const cred = setting.credibilityPointSetting;
  const int = setting.interactivityPointSetting;
  const attr = setting.attractivenessPointSetting;

  for(let i=0;i<cred.length;i++) {
    const factor = cred[i].factorSettingList;
    for(let j=0;j<factor.length;j++) {
      retVal.push({"text":"W"});
      retVal.push({"text":"A0"});
      retVal.push({"text":"A1"});
      retVal.push({"text":"D.V"});
      retVal.push({"text":"반영"});
    }
  }
  for(let i=0;i<int.length;i++) {
    const factor = int[i].factorSettingList;
    for(let j=0;j<factor.length;j++) {
      retVal.push({"text":"W"});
      retVal.push({"text":"A0"});
      retVal.push({"text":"A1"});
      retVal.push({"text":"D.V"});
      retVal.push({"text":"반영"});
    }
  }
  for(let i=0;i<attr.length;i++) {
    const factor = attr[i].factorSettingList;
    for(let j=0;j<factor.length;j++) {
      retVal.push({"text":"W"});
      retVal.push({"text":"A0"});
      retVal.push({"text":"A1"});
      retVal.push({"text":"D.V"});
      retVal.push({"text":"반영"});
    }
  }

  return retVal;
};

const getData = (settings) => {
  let retVal = [];
  let prevItem = [];
  for(let i=settings.length-1;i>=0;i--) {
    let rowItem = [];
    rowItem.push({"text":settings[i].updatedAt, "type":"update"});
    let cred = settings[i].credibilityPointSetting;
    for(let j=0;j<cred.length;j++) {
      rowItem.push({"text":cred[j].termType, "type":"none"});
      let tt = cred[j].timeWindowDaySize ? cred[j].timeWindowDaySize : 'N/A';
      rowItem.push({"text":tt, "type":"none"});
      let st = cred[j].processSkipDays ? cred[j].processSkipDays : 'N/A';
      rowItem.push({"text":st, "type":"none"});

      let factorSettingList = cred[j].factorSettingList;
      for(let k=0;k<factorSettingList.length;k++) {
        let factor = factorSettingList[k];
        let w = factor.weight * 100;
        rowItem.push({"text":w, "type":"ratio"});
        rowItem.push({"text":factor.thresholdLower, "type":"none"});
        rowItem.push({"text":factor.thresholdUpper, "type":"none"});
        rowItem.push({"text":factor.defaultScore, "type":"none"});
        let u = factor.use ? 'O' : 'X';
        rowItem.push({"text":u, "type":"none"});
      }
    }
    let int = settings[i].interactivityPointSetting;
    for(let j=0;j<int.length;j++) {
      rowItem.push({"text":int[j].termType, "type":"none"});
      let tt = int[j].timeWindowDaySize ? int[j].timeWindowDaySize : 'N/A';
      rowItem.push({"text":tt, "type":"none"});
      let st = int[j].processSkipDays ? int[j].processSkipDays : 'N/A';
      rowItem.push({"text":st, "type":"none"});

      let factorSettingList = int[j].factorSettingList;
      for(let k=0;k<factorSettingList.length;k++) {
        let factor = factorSettingList[k];
        let w = factor.weight * 100;
        rowItem.push({"text":w, "type":"ratio"});
        rowItem.push({"text":factor.thresholdLower, "type":"none"});
        rowItem.push({"text":factor.thresholdUpper, "type":"none"});
        rowItem.push({"text":factor.defaultScore, "type":"none"});
        let u = factor.use ? 'O' : 'X';
        rowItem.push({"text":u, "type":"none"});
      }
    }
    let attr = settings[i].attractivenessPointSetting;
    for(let j=0;j<attr.length;j++) {
      rowItem.push({"text":attr[j].termType, "type":"none"});
      let tt = attr[j].timeWindowDaySize ? attr[j].timeWindowDaySize : 'N/A';
      rowItem.push({"text":tt, "type":"none"});
      let st = attr[j].processSkipDays ? attr[j].processSkipDays : 'N/A';
      rowItem.push({"text":st, "type":"none"});

      let factorSettingList = attr[j].factorSettingList;
      for(let k=0;k<factorSettingList.length;k++) {
        let factor = factorSettingList[k];
        let w = factor.weight * 100;
        rowItem.push({"text":w, "type":"ratio"});
        rowItem.push({"text":factor.thresholdLower, "type":"none"});
        rowItem.push({"text":factor.thresholdUpper, "type":"none"});
        rowItem.push({"text":factor.defaultScore, "type":"none"});
        let u = factor.use ? 'O' : 'X';
        rowItem.push({"text":u, "type":"none"});
      }
    }

    rowItem.push({"text":settings[i].confirmationMonths, "type":"none"});
    rowItem.push({"text":settings[i].admin_account, "type":"account"});

    const rr = rowItem.map((prevItem => (item, index) => {
      if(item.type === "update") {
        let updatedAt = item.text;
        return (
          <td key={index}>
            <span className={cx('date_for')}>
              {updatedAt.split(' ')[0].substring(2)}
              <em>
                {updatedAt.split(' ')[1]}
              </em>
            </span>
          </td>
        );
      } else if(item.type === "ratio") {
        return <td key={index}>{item.text}%</td>
      } else if(item.type === "account") {
        return <td key={index}>{item.text}</td>
      } else {
        if(prevItem.length > 0 && prevItem[index].text !== item.text) {
          return <td key={index} className={cx('diff')}>{item.text}</td>;
        } else {
          return <td key={index}>{item.text}</td>
        }
      }
    })(prevItem));
    const r = 
      <tr>
        { rr }
      </tr>;
    retVal.unshift(r);
    prevItem = rowItem;
  }
  return retVal;
}

const HistoryContent = ({
  type,
  historyList,                      // 히스토리 리스트
  total,                            // 히스토리 전체 개수
  viewType,                         // view count
  startPage,                        // current page
  onChangePage,                     // paging handler,
  onChangeViewType,                 // view count handler
}) => {
  // let body = document.getElementsByTagName('body')[0];
  // body.style.overflowX = 'hidden';

  if(historyList.length === 0) {
    return (
      <div></div>
    );
  }

  let sList = [];
  for(let i=0;i<historyList.length;i++) {
    let s = JSON.parse(historyList[i].setting_json);
    s["updatedAt"] = historyList[i].updatedAt;
    s["admin_account"] = historyList[i].admin_account;
    sList.push(s);
  }

  const credColSpan = countColSpan(sList[0].credibilityPointSetting);
  const intColSpan = countColSpan(sList[0].interactivityPointSetting);
  const attrColSpan = countColSpan(sList[0].attractivenessPointSetting);

  const row2Item = getRow2Item(sList[0]);
  const row2 = row2Item.map((item, index) => (
    <th colSpan={item.span} key={index}>{item.status}</th>
  ));
  const row3Item = getRow3Item(sList[0]);
  const row3 = row3Item.map((item, index) => {
    if(item.spanType === "col") {
      return <th colSpan={item.span} key={index}>{item.text}</th>;
    } else {
      return <th rowSpan={item.span} key={index}>{item.text}</th>;
    }
  });
  const row4Item = getRow4Item(sList[0]);
  const row4 = row4Item.map((item, index) => (
    <th key={index}>{item.text}</th>
  ));

  const items = getData(sList);

  return (
    <div>
      <PageLocation locArr={[{ loc: '신뢰지수' }, { loc: '신뢰지수 설정 히스토리' }, {loc: `${type}`, active: true }]} />
      <div className={cx('title_wrapper')}>
        <div className={cx('left')}>
          <h2 className={cx('page_title')}>{type} 신뢰지수 설정 히스토리</h2>
        </div>
        <div className={cx('right')}>
          <div className={cx('list_filter_box')}>
            {/* 상세검색 */}
            <div className={cx('filter_row', 'search_box')}>
              <div className={cx('seach_input_wrap')}>
                <div className={cx('wz', 'dropdown', 'small', 'outline', 'select_box')}>
                  <select onChange={onChangeViewType}>
                    <option>10개씩 보기</option>
                    <option>15개씩 보기</option>
                    <option>20개씩 보기</option>
                    <option>25개씩 보기</option>
                    <option>50개씩 보기</option>
                    <option>100개씩 보기</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={cx('table_wrapper')}>
        <Table striped className={ cx('table') }>
          <thead>
            <tr>
              <th rowSpan="4" className={cx('td_updated')}>업데이트 날짜</th>
              <th colSpan={credColSpan} className={cx('right_border')}>평판</th>
              <th colSpan={intColSpan} className={cx('right_border')}>소통</th>
              <th colSpan={attrColSpan} className={cx('right_border')}>인기</th>
              <th rowSpan="4" className={cx('td_update')}>처리 종료일</th>
              <th rowSpan="4" className={cx('td_latest')}>최종 업데이트 진행자</th>
            </tr>
            <tr>
              { row2 }
            </tr>
              { row3 }
            <tr>
              { row4 }
            </tr>
          </thead>
          <tbody>
            { items }
          </tbody>
        </Table>
      </div>
      <PaginatorContainer
        onChange={onChangePage}
        activePage={startPage}
        totalCount={total}
        viewCount={viewType}
      />
    </div>
  );
};

export default HistoryContent;
