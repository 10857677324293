import React, { Component } from 'react';
import { connect } from 'react-redux';
import { openPopup, closePopup } from '../../../reducers/dialogReducer';
import { fetchLogoutApi } from '../../../reducers/userReducer';

// Components
import CompanyInfoTab from './CompanyInfoTab/CompanyInfoTab'
import ProjectInfoTab from './ProjectInfoTab/ProjectInfoTab'

import className from 'classnames/bind';
import commonStyle from './MakerDialog.module.scss';
const cx = className.bind(commonStyle);

class MakerDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShow: false,
      selTab: 0,
      maker_id: -1,
      project_id: -1,
      project_type: "",
      maker_info: null,
      project_info: null,
      credibility_val: 0,
      interactivity_val : 0,
      attractiveness_val: 0,
      bIndex: false,
      admin_eval: 5,
      bIndex2: false,
      project_progress: false,
      isChange: false,
      bChanging: false,
    };
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.dialog.dialogType === 'maker') {
      this.setState({
        maker_id: nextProps.dialog.dialogData.content.maker_id,
        project_id: nextProps.dialog.dialogData.content.project_id,
        project_type: nextProps.dialog.dialogData.content.project_type,
        selTab: nextProps.dialog.dialogData.content.sel_tab,
      });
      this.getMaker(nextProps.dialog.dialogData.content.maker_id);
      // this.setState({ isShow: true });
    } else {
      this.setState({ isShow: false });
    }
  };

  getMaker = async (id) => {
    fetch(`${process.env.REACT_APP_RELAY_API_HOST}api/v1/wtp/showCoperation?makerId=${id}` , {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'JSESSIONID': this.props.user.token,
      }
    })
    .then(response => {
      if(response.status === 401) {
        alert('권한이 없습니다. 권한이 있는 계정으로 로그인 해주세요');
        const {
          loginUrl
         } = this.props.user
        this.props.fetchLogoutApi(this.props.user.token).then(() => {
          window.location.href = loginUrl;
        });
      } else {
        return response.json()
      }
    })
    .then(response => {
      if(response.result) {
        this.setState({
          maker_info: response.response,
        }, () => {
          let body = document.getElementsByTagName('body')[0];
          body.style.overflowY = 'hidden';
          this.getProject(this.state.project_id, this.state.project_type);
        });
      }
    });
  }

  getProject = async (id, type) => {
    let strType = '';
    if (type === '1' ) {
      strType = 'FUNDING';
    } else if (type === '0') {
      strType = 'EQUITY';
    } else {
      strType = type;
    }
    this.setState({
      project_type: strType,
    }, () => {
      fetch(`${process.env.REACT_APP_RELAY_API_HOST}api/v1/wtp/showProject?projectId=${id}&projectType=${strType}` , {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'JSESSIONID': this.props.user.token,
        }
      })
      .then(response => {
        if(response.status === 401) {
          alert('권한이 없습니다. 권한이 있는 계정으로 로그인 해주세요');
          const {
            loginUrl
           } = this.props.user
          this.props.fetchLogoutApi(this.props.user.token).then(() => {
            window.location.href = loginUrl;
          });
        } else {
          return response.json()
        }
      })
      .then(response => {
        if(response.result) {
          this.setState({
            project_info: response.response,
            credibility_val: response.response.credibilityResult,
            interactivity_val : response.response.interactivityResult,
            attractiveness_val: response.response.attractivenessResult,
            admin_eval: response.response.makerAdminEvaluation,
            project_progress: true,
            isShow: true,
          });
        } else {
          this.setState({
            isShow: true,
            project_progress: true,
            selTab: 0,
          })
        }
      });  
    });
  }

  handleChangeTab = (idx) => {
    if(idx === 1) {
      // project 정보 확인
      if(this.state.project_info === null && this.state.project_progress) {
        alert('진행된 캠페인이 없습니다');
        return;
      } else  if(this.state.project_info === null && !this.state.project_progress) {
        alert('캠페인을 가져오는 중입니다. 잠시만 기다려주세요.');
        return;
      }
    }
    this.setState({
      selTab: idx,
    });
  };

  handleChangeResult = (e) => {
    const {
      credibility_val,
      interactivity_val,
      attractiveness_val,
      project_info,
      bChanging
    } = this.state;

    if(bChanging) {
      alert('변경사항 적용 중입니다. 잠시만 기다려 주세요');
      return;
    }

    this.setState({
      bChanging: true,
    }, () => {
      console.log(credibility_val);
      if(credibility_val !== 999 && (credibility_val > 1 || credibility_val < -1 || isNaN(credibility_val))) {
        // alert
        alert('평판지표는 -1 ~ 1의 값이어야 합니다.');
        document.querySelector('input[name=credibility_val]').focus();
        return;
      } else if(interactivity_val !== 999 && (interactivity_val > 1 || interactivity_val < -1 || isNaN(interactivity_val))) {
        alert('소통지표는 -1 ~ 1의 값이어야 합니다.');
        document.querySelector('input[name=interactivity_val]').focus();
        return;
      } else if(attractiveness_val !== 999 && (attractiveness_val > 1 || attractiveness_val < -1 || isNaN(attractiveness_val))) {
        alert('인기지표는 -1 ~ 1의 값이어야 합니다.');
        document.querySelector('input[name=attractiveness_val]').focus();
        return;
      } else {
        if(interactivity_val !== project_info.interactivityResult || attractiveness_val !== project_info.attractivenessResult) {
          if(project_info.projectStatus !== 0 && project_info.projectStatus !== 1 && project_info.projectStatus !== 2) {
            alert('소통/인기 지표는 오픈 전 상태일때 변경할 수 없습니다.');
            return;
          }
        }
        fetch(`${process.env.REACT_APP_RELAY_API_HOST}api/v1/wtp/updateScore` , {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'JSESSIONID': this.props.user.token,
          },
          body: JSON.stringify({
            makerId: this.state.maker_id,
            projectId: this.state.project_id,
            projectType2: this.state.project_type,
            credibilityResult: credibility_val,
            interactivityResult: interactivity_val,
            attractivenessResult: attractiveness_val,
            latestPerformer: this.props.user.id,
          })
        })
        .then(response => {
          if(response.status === 401) {
            alert('권한이 없습니다. 권한이 있는 계정으로 로그인 해주세요');
            const {
              loginUrl
            } = this.props.user
            this.props.fetchLogoutApi(this.props.user.token).then(() => {
              window.location.href = loginUrl;
            });
          } else {
            return response.json()
          }
        })
        .then(response => {
          if(response.result) {
            alert('수정이 완료되었습니다.');
            this.setState({
              isChange: true,
              bIndex: false,
              bChanging: false,
            }, () => {
              this.getProject(this.state.project_id, this.state.project_type);
            });
          } else {
            alert('수정 중 오류가 발생하였습니다. 다시 시도해 주십시요');
            this.setState({
              isChange: true,
              bIndex: false,
              bChanging: false, 
            }, () => {
              this.getProject(this.state.project_id, this.state.project_type);
            });
          }
        }); 
      }
    });
  };

  handleChangeAdminEval = (e) => {
    const {
      admin_eval,
    } = this.state;

    if(admin_eval > 10 || admin_eval < 0 || admin_eval === '' || isNaN(parseInt(admin_eval))) {
      // alert
      alert('admin 평가값은 0 ~ 10의 값이어야 합니다.');
      document.querySelector('input[name="admin_eval"]').focus();
      return;
    } else {
      fetch(`${process.env.REACT_APP_RELAY_API_HOST}api/v1/wtp/updateEvaluation` , {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'JSESSIONID': this.props.user.token,
        },
        body: JSON.stringify({
          makerId: this.state.maker_id,
          projectId: this.state.project_id,
          projectType: this.state.project_type,
          makerAdminEvaluation: admin_eval,
          latestPerformer: this.props.user.id,
        })
      })
      .then(response => {
        if(response.status === 401) {
          alert('권한이 없습니다. 권한이 있는 계정으로 로그인 해주세요');
          const {
            loginUrl
           } = this.props.user
          this.props.fetchLogoutApi(this.props.user.token).then(() => {
            window.location.href = loginUrl;
          });
        } else {
          return response.json()
        }
      })
      .then(response => {
        if(response.result) {
          let project_info = {...this.state.project_info};
          project_info.makerAdminEvaluation = admin_eval;
          this.setState({
            project_info
          }, () => { alert('수정이 완료되었습니다.'); });
        }
      }); 
    } 
  }

  closePopup = () => {
    const { dialog } = this.props;
    const { dialogData } = dialog;

    let body = document.getElementsByTagName('body')[0];
    body.style.overflowY = 'auto';
    this.setState({
      isShow: false,
      selTab: 0,
      maker_id: -1,
      project_id: -1,
      project_type: "",
      maker_info: null,
      project_info: null,
      credibility_val: 0,
      interactivity_val : 0,
      attractiveness_val: 0,
      bIndex: false,
      admin_eval: 5,
      bIndex2: false,
      project_progress: false,
      bChanging: false,
    }, () => {
      this.props.closePopup();
      if(dialogData.hasOwnProperty('closeCallback') && this.state.isChange) {
        this.setState({
          isChange: false,
        }, () => {
          dialogData.closeCallback();
        });
      }
    });
  }

  onChange = (e) => {
    let credVal = parseInt(document.querySelector('input[name=credibility_val]').value);
    let interVal = parseInt(document.querySelector('input[name=interactivity_val]').value);
    let attrVal = parseInt(document.querySelector('input[name=attractiveness_val]').value);

    let flag = true;
    if(credVal === this.state.project_info.credibilityResult &&
       interVal === this.state.project_info.interactivityResult &&
       attrVal === this.state.project_info.attractivenessResult) {
      flag = false;
    }
    this.setState({
      credibility_val: credVal,
      interactivity_val: interVal,
      attractiveness_val: attrVal,
      bIndex: flag,
    });
  };

  indexClick = (name) => {
    let value = this.state[name];
    if(value === 1) {
      value = -1;
    } else {
      value++;
    }
    this.setState({
      [name]: value,
    }, () => {
      const {
        credibility_val,
        interactivity_val,
        attractiveness_val,
        project_info,
      } = this.state;
      let flag = true;
      if(credibility_val === project_info.credibilityResult &&
         interactivity_val === project_info.interactivityResult &&
         attractiveness_val === project_info.attractivenessResult) {
           flag = false;
         }
      this.setState({
        bIndex: flag,
      })
    });
  }

  onChangeAdmin = (e) => {
    let flag = true;
    if(parseInt(e.target.value) === parseInt(this.state.project_info.makerAdminEvaluation)) {
      flag = false;
    }
    this.setState({
      admin_eval: e.target.value,
      bIndex2: flag,
    });
  }

  render() {
    const { isShow, selTab, maker_info, project_info } = this.state;
    const { handleChangeTab, handleChangeResult, closePopup } = this;
    if(maker_info === null) {
      return (
        <>
        </>
      );
    }
    return isShow ? (
      <div className={cx('shadow')}>
        <div className={cx('dialog_wrap', `${selTab === 0 ? 'tab1' : 'tab2'}`)}>
          <div className={cx('dialog_close')}><button type='button' onClick={closePopup}><i className='icon close'></i></button></div>
          <div className={cx('dialog_pid')}>
            <span className={cx('pid')}>{maker_info.makerId}</span>
          </div>
          <div className={cx('dialog_title')}>
            <span className={cx('title')}>{ maker_info.makerName }</span>
          </div>
          <div className={cx('dialog_content')}>
            <div className={cx('tab_wrap')}>
              <button className={cx('tab_item', { active: selTab === 0 })} onClick={() => handleChangeTab(0)}>기업정보</button>
              <button className={cx('tab_item', { active: selTab === 1 })} onClick={() => handleChangeTab(1)}>프로젝트 정보</button>
            </div>
            {selTab === 0 && 
              <div className={cx('content_inner')}>
                <CompanyInfoTab maker_info={ maker_info } />
              </div>
            }
            {selTab === 1 && 
              <div className={cx('content_inner')}>
                {
                  project_info &&
                    <ProjectInfoTab
                      project_info={ project_info }
                      credibility_val={this.state.credibility_val}
                      interactivity_val={this.state.interactivity_val}
                      attractiveness_val={this.state.attractiveness_val}
                      bIndex={this.state.bIndex}
                      handleChangeResult={ handleChangeResult }
                      onChange={this.onChange}
                      admin_eval={this.state.admin_eval}
                      onChangeAdmin={this.onChangeAdmin}
                      bIndex2={this.state.bIndex2}
                      handleChangeAdminEval={this.handleChangeAdminEval}
                      indexClick={this.indexClick}
                    />
                }
              </div>
            }
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default connect(({ user, dialog }) => ({ user, dialog }), ({ fetchLogoutApi, openPopup, closePopup }))(MakerDialog);
