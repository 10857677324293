import React from 'react';

// Plugin
import { NavLink, withRouter } from 'react-router-dom';

// CSS module
import styles from './LeftMenu.module.scss';
import className from 'classnames/bind';
const cx = className.bind(styles);

const LeftMenu = ({ menuList, handleExpand }) => {
  return (
    <article id={ cx('leftMenu') }>
      <ul>
        {
          menuList.map((val, idx) => (
            <li key={idx} className={cx('menu_depth1')}>
              <button className={cx('menu_depth1_title', { arrow: val.hasOwnProperty('children'), expand: val.isExpand })} onClick={() => handleExpand(idx)}>
                <span className={cx({ active: window.location.pathname === val.link })}>{val.name}</span>
              </button>
              {val.hasOwnProperty('children') && (
                <ul className={cx('menu_depth2')}>
                  {
                    val.children.map(({ link, name, children }, idx2) => (
                      <li key={idx2}>
                        <button className={cx('menu_depth2_title')}>
                          <i className='icon subdirectory' />
                          {
                            link ?
                              <NavLink to={`${link}`} activeClassName={cx('active')}>{name}</NavLink>
                            :
                              <span>{name}</span>
                          }
                        </button>
                        {
                          children && (
                            <ul className={cx('menu_depth3')}>
                              { children.map(({ name, link }, idx3) => (
                                <li key={idx3}>
                                  <button className={cx('menu_depth3_title')}>
                                    <i className='icon subdirectory' />
                                    <NavLink activeClassName={cx('active')} to={link}>{name}</NavLink>
                                  </button>
                                </li>
                              )) }
                            </ul>
                          )
                        }
                      </li>
                    ))
                  }
                </ul>
              )}
            </li>
          ))
          }
      </ul>
    </article>
  );
};

export default withRouter(LeftMenu);
