import React from 'react';

// Plugin
import { Table } from 'react-bootstrap';

// Components
import PageLocation from '../PageLocation';
import PaginatorContainer from '../../containers/PaginatorContainer';
import SingleDatePicker from '../CustomDatePicker';

// CSS module
import classNames from 'classnames/bind';
import style from './MakerContent.module.scss';
const cx = classNames.bind(style);

const indexContent = (type, value) => {
  let retVal = null;
  if(type === 'credibility') {
    if(value === undefined || value === null || value === 999) {
      retVal = 
        <>
          <div className={cx('rect_back')}></div>
        </>
    } else if(value < 0) {
      retVal = 
        <>
          <div className={cx('rect_back')}><span className={cx('low')}></span></div>
          {/* <span>{value}</span> */}
        </>
    } else if(value > 0) {
      retVal = 
        <>
          <div className={cx('rect_back')}><span className={cx('high')}></span></div>
          {/* <span>{value}</span> */}
        </>
    } else {
      retVal = 
        <>
          <div className={cx('rect_back')}><span className={cx('mid')}></span></div>
          {/* <span>{value}</span> */}
        </>
    }
  } else if(type === 'interactivity') {
    if(value === undefined || value === null || value === 999) {
      retVal = 
        <>
          <div className={cx('rect_back')}></div>
        </>
    } else if(value < 0) {
      retVal = 
        <>
          <div className={cx('rect_back')}><span className={cx('low')}></span></div>
          {/* <span>{value}</span> */}
        </>
    } else if(value > 0) {
      retVal = 
        <>
          <div className={cx('rect_back')}><span className={cx('high')}></span></div>
          {/* <span>{value}</span> */}
        </>
    } else {
      retVal = 
        <>
          <div className={cx('rect_back')}><span className={cx('mid')}></span></div>
          {/* <span>{value}</span> */}
        </>
    }
  } else if(type === 'attractiveness') {
    if(value === undefined || value === null || value === 999) {
      retVal = 
        <>
          <div className={cx('rect_back')}></div>
        </>
    } else if(value < 0) {
      retVal = 
        <>
          <div className={cx('rect_back')}><span className={cx('low')}></span></div>
          {/* <span>{value}</span> */}
        </>
    } else if(value > 0) {
      retVal = 
        <>
          <div className={cx('rect_back')}><span className={cx('high')}></span></div>
          {/* <span>{value}</span> */}
        </>
    } else {
      retVal = 
        <>
          <div className={cx('rect_back')}><span className={cx('mid')}></span></div>
          {/* <span>{value}</span> */}
        </>
    }
  } else if(type === 'none') {
    retVal = 
      <>
        <div className={cx('rect_back')}></div>
      </>
  }
  return retVal;
}

const tableContent = (handleTrClick, makerList) => {
  let equityArray = [];
  let rewardArray = [];
  const items = makerList.map((val, idx) => {
    let retVal = [];
    const projectCount = val.detailInfo.length;
    let equityCount = 0;
    let rewardCount = 0;
    equityArray = [];
    rewardArray = [];
    for(let i=0;i<projectCount;i++) {
      if(val.detailInfo[i].projectType === 'EQUITY') {
        if(equityArray.indexOf(val.detailInfo[i].projectId) > -1) {
        } else {
          equityArray.push(val.detailInfo[i].projectId);
          equityCount++;
        }
      } else if(val.detailInfo[i].projectType === 'FUNDING') {
        if(rewardArray.indexOf(val.detailInfo[i].projectId) > -1) {
        } else {
          rewardArray.push(val.detailInfo[i].projectId);
          rewardCount++;
        }
      }
    }
    let equityIndex = 0;
    let rewardIndex = 0;
    let realCount = equityArray.length + rewardArray.length;
    equityArray = [];
    rewardArray = [];
    for(let i=0;i<projectCount;i++) {
      if(val.detailInfo[i].projectType === 'EQUITY') {
        if(equityArray.indexOf(val.detailInfo[i].projectId) > -1) {
          continue;
        } else {
          equityArray.push(val.detailInfo[i].projectId);
        }
      } else if(val.detailInfo[i].projectType === 'FUNDING') {
        if(rewardArray.indexOf(val.detailInfo[i].projectId) > -1) {
          continue;
        } else {
          rewardArray.push(val.detailInfo[i].projectId);
        }
      }
      let status = ''
      if(val.detailInfo[i].projectStatus === 'COMINGSOON') {
        status = '오픈예정';
      } else if(val.detailInfo[i].projectStatus === 'IN_PROGRESS') {
        status = '펀딩중';
      } else if(val.detailInfo[i].projectStatus === 'IN_SCREENING') {
        status = '심사중';
      } else {
        status = '펀딩 종료';
      }
      let updatedAt = val.detailInfo[i].updatedAt;
      if(!updatedAt) {
        updatedAt='2019-12-12 00:00:00';
      }

      let subItem = null;
      if(equityCount > 0 && equityIndex < equityCount) {
        if(equityIndex === 0) {
          equityIndex++;
          subItem = <td rowSpan={equityCount}>투자</td>;
        } else {
          equityIndex++;
        }
      } else if(rewardCount > 0 && rewardIndex < rewardCount) {
        if(rewardIndex === 0) {
          rewardIndex++;
          subItem = <td rowSpan={rewardCount}>펀딩</td>
        } else {
          rewardIndex++;
        }
      }
      
      let item = 
        <tr onClick={e => handleTrClick(e)} maker_id={`${val.makerId}`} project_id={`${val.detailInfo[i].projectId}`} project_type={`${val.detailInfo[i].projectType}`} key={`${idx} + ${i}`}>
          {i === 0 &&
            <>
              <td rowSpan={realCount}>{val.makerId}</td>
              <td rowSpan={realCount}>{val.makerName}</td>
            </>
          }
          { subItem }
          <td>{val.detailInfo[i].projectId}</td>
          <td className={cx('left')}>
            <span className={cx('ellipse')}>
              {val.detailInfo[i].projectName}
            </span>
          </td>
          <td>{status}</td>
          <td>
          {
            val.detailInfo[i].projectStartAt &&
              val.detailInfo[i].projectStartAt.substring(2,10)
          }
          </td>
          <td>
          {
            val.detailInfo[i].projectEndAt &&
              val.detailInfo[i].projectEndAt.substring(2,10)
          }
          </td>
          {
            val.detailInfo[i].projectStatus !== 'IN_SCREENING' ?
              <>
                <td className={cx('index')}>{indexContent('credibility', val.detailInfo[i].credibilityResult)}</td>
                <td className={cx('index')}>{indexContent('interactivity', val.detailInfo[i].interactivityResult)}</td>
                <td className={cx('index')}>{indexContent('attractiveness', val.detailInfo[i].attractivenessResult)}</td>
              </>
            : 
              <>
                <td className={cx('index')}>{indexContent('credibility', val.detailInfo[i].credibilityResult)}</td>
                <td className={cx('index')}>{indexContent('none', val.detailInfo[i].interactivityResult)}</td>
                <td className={cx('index')}>{indexContent('none', val.detailInfo[i].attractivenessResult)}</td>
              </>
          }
          <td>{val.detailInfo[i].latestPerformer ? val.detailInfo[i].latestPerformer : 'auto'}</td>
          <td>
            <span className={cx('date_for')}>
              {updatedAt.split(' ')[0].substring(2)}
              <em>
                {updatedAt.split(' ')[1]}
              </em>
            </span>
          </td>
        </tr>;
      retVal.push(item);
    }
    return retVal;
  });
  return items;
}

const MakerContent = ({
  handleTrClick,            // row click handler
  makerList,                // maker list
  onChangePage,             // page change handler
  startPage,                // current page
  totalCount,               // total count
  viewType,                 // view count
  startDatePickerFocus,     // start datepicker focus 여부
  startDate,                // 시작 날짜
  endDatePickerFocus,       // end datepicker focus 여부
  endDate,                  // 종료 날짜
  onChangeDateRange,        // datepicker change handler
  onFocusChange,            // datepicker focus change handler,
  typeCheckList,            // type checkbox 리스트
  statusCheckList,          // status checkbox 리스트
  dateCheckList,          // status checkbox 리스트
  onChangeCheckbox,         // checkbox handler
  keyword,                  // search keyword
  onChangeSearchText,       // search keyword change handler
  onSearch,                 // search handler
  onChangeViewType,         // viewType handler,
  onKeyUp,                  // keyUp handler,
}) => {
  const items = tableContent(handleTrClick, makerList);
  return (
    <div>
      <PageLocation locArr={[{ loc: '신뢰지수' }, { loc: '메이커별 신뢰지수', active: true }]} />
      <div className={cx('title_wrapper')}>
        <div className={cx('left')}>
          <h2 className={cx('page_title')}>메이커별 신뢰지수</h2>
        </div>
        <div className={cx('right')}>
          <div className={cx('wz', 'input', 'tiny', 'input_box')}>
            <input
              type='text'
              placeholder='메이커ID/메이커명/프로젝트명/프로젝트ID'
              value={keyword}
              onChange={onChangeSearchText}
              onKeyUp={onKeyUp}
            />
          </div>
        </div>
      </div>
      <div className={cx('filter_wrapper')}>
        <div className={cx('date_wrapper')}>
          <label className={cx('title')}>프로젝트 기간</label>
          <div className={cx('date_range_input_wrap')}>
            <SingleDatePicker
              onDateChange={date => { onChangeDateRange('startDate', date); }}
              onFocusChange={({ focused }) => { onFocusChange('startDatePickerFocus', focused); }}
              focused={startDatePickerFocus}
              date={startDate}
              isOutsideRange={() => false}
              placeholder="검색시작일"
              id="startDate"
            />
            <span className={cx('range_ico')}>~</span>
            <SingleDatePicker
              onDateChange={date => { onChangeDateRange('endDate', date); }}
              onFocusChange={({ focused }) => { onFocusChange('endDatePickerFocus', focused); }}
              focused={endDatePickerFocus}
              date={endDate}
              isOutsideRange={() => false}
              placeholder="검색종료일"
              id="endDate"
            />
          </div>
        </div>
        <div className={cx('type_wrapper')}>
          <label className={cx('title')}>프로젝트 타입</label>
          <div className={cx('checkbox_wrap')}>
              {
                (
                  typeCheckList.map((checkbox, index) => {
                    return <label key={checkbox.value} className={cx('wz', 'checkbox')}>
                      <input
                        type='checkbox'
                        name='typeCheckList'
                        value={checkbox.value}
                        checked={checkbox.checked}
                        onChange={(e) => onChangeCheckbox(e, index)}
                      />
                      <span>{checkbox.label}</span>
                    </label>
                  })
                )
              }
          </div>
        </div>
        <div className={cx('status_wrapper')}>
          <label className={cx('title')}>프로젝트 상태</label>
          <div className={cx('checkbox_wrap')}>
            {
              (
                statusCheckList.map((checkbox, index) => {
                  return <label key={checkbox.value} className={cx('wz', 'checkbox')}>
                    <input
                      type='checkbox'
                      name='statusCheckList'
                      value={checkbox.value}
                      checked={checkbox.checked}
                      onChange={(e) => onChangeCheckbox(e, index)}
                    />
                    <span>{checkbox.label}</span>
                  </label>
                })
              )
            }
          </div>
        </div>
        <div className={cx('detail_wrapper')}>
          <div className={cx("left")}>
            <label className={cx('title')}>기간 조건</label>
            <div className={cx('checkbox_wrap')}>
              {
                (
                  dateCheckList.map((checkbox, index) => {
                    return <label key={checkbox.value} className={cx('wz', 'checkbox')}>
                      <input
                        type='checkbox'
                        name='dateCheckList'
                        value={checkbox.value}
                        checked={checkbox.checked}
                        onChange={(e) => onChangeCheckbox(e, index)}
                      />
                      <span>{checkbox.label}</span>
                    </label>
                  })
                )
              }
            </div>
          </div>
          <div className={cx('right')}>
            <button
              className={cx('wz button primary radius', 'button')}
              onClick={onSearch}
            >검색</button>
          </div>
        </div>
      </div>
      <div className={cx('view_wrapper')}>
        <div className={cx('right')}>
          <div className={cx('list_filter_box')}>
            {/* 상세검색 */}
            <div className={cx('filter_row', 'search_box')}>
              <div className={cx('seach_input_wrap')}>
                <div className={cx('wz', 'dropdown', 'small', 'outline', 'select_box')}>
                  <select onChange={onChangeViewType}>
                    <option value="10">10개씩 보기</option>
                    <option value="15">15개씩 보기</option>
                    <option value="20">20개씩 보기</option>
                    <option value="25">25개씩 보기</option>
                    <option value="50">50개씩 보기</option>
                    <option value="100">100개씩 보기</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Table striped className={ cx('table') }>
        <thead>
          <tr>
            <th rowSpan="2" className={cx('td_makerid')}>메이커ID</th>
            <th rowSpan="2" className={cx('td_maker')}>메이커명</th>
            <th rowSpan="2" className={cx('td_type')}>프로젝트 타입</th>
            <th rowSpan="2" className={cx('td_id')}>프로젝트 ID</th>
            <th rowSpan="2" className={cx('td_project')}>프로젝트 명</th>
            <th rowSpan="2" className={cx('td_status')}>프로젝트 상태</th>
            <th rowSpan="2" className={cx('td_startat')}>프로젝트 오픈일</th>
            <th rowSpan="2" className={cx('td_endat')}>프로젝트 종료일</th>
            <th colSpan="3" className={cx('td_wtp')}>신뢰지수</th>
            <th rowSpan="2" className={cx('td_updater')}>최종 업데이트 진행자</th>
            <th rowSpan="2" className={cx('td_updated')}>최종 업데이트 일시</th>
          </tr>
          <tr>
            <th>평판</th>
            <th>소통</th>
            <th>인기</th>
          </tr>
        </thead>
        <tbody>
          {
            makerList.length > 0 ? (
              items
            ) : (
              <tr><td colSpan='13' style={{ height: '500px', backgroundColor: '#f6f6f6' }}>검색된 내용이 없습니다.</td></tr>
            )
          }
        </tbody>
      </Table>
      <PaginatorContainer
        onChange={onChangePage}
        activePage={startPage}
        totalCount={totalCount}
        viewCount={viewType}
      />
    </div>
  );
};

export default MakerContent;
