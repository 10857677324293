import React, { PureComponent } from 'react';

// Plugin
import { connect } from 'react-redux';

// Components
import MakerContent from '../components/MakerContent';

// dialog
import { openPopup, closePopup } from '../reducers/dialogReducer';
import { fetchLogoutApi } from '../reducers/userReducer';


class MakerPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      makerList: [],
      total: 1,
      projectType: -1,
      viewType: 10,
      startPage: 1,
      sortType: '',
      sort: 'DESC',
      startDate: null,
      startDatePickerFocus: false,
      endDate: null,
      endDatePickerFocus: false,
      typeCheckList: [
        { label: '투자', value: 'EQUITY', checked: true },
        { label: '펀딩', value: 'FUNDING', checked: true }
      ],
      statusCheckList: [
        { label: '심사중', value: 'IN_SCREENING', checked: true },
        { label: '오픈예정', value: 'COMINGSOON', checked: true },
        { label: '펀딩중', value: 'IN_PROGRESS', checked: true },
        { label: '펀딩 종료(업데이트중)', value: 'END1', checked: true },
        { label: '펀딩 종료(확정)', value: 'END2', checked: true }
      ],
      dateCheckList: [
        { label: '오픈일', value: 0, checked: false },
        { label: '종료일', value: 1, checked: false }
      ],
      keyword: '',
      initialFlag: true,
    };
  };

  componentDidMount = () => {
    if(this.props.projectId && this.props.projectType) {
      this.loadListWithId();
    } else {
      this.loadList();
    }
  };

  loadListWithId = async () => {
    const {
      projectId,
      projectType
    } = this.props;

    this.props.openPopup({
      dialogType: 'loader',
      dialogData: {
        isShow: true,
        width: 120,
        height: 120,
      }
    });

    fetch(`${process.env.REACT_APP_RELAY_API_HOST}api/v1/wtp/getMakerId?projectId=${projectId}&projectType=${projectType}` , {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'JSESSIONID': this.props.user.token,
      }
    })
    .then(response => {
      if(response.status === 401) {
        alert('권한이 없습니다. 권한이 있는 계정으로 로그인 해주세요');
        const {
          loginUrl
         } = this.props.user
        this.props.fetchLogoutApi(this.props.user.token).then(() => {
          window.location.href = loginUrl;
        });
      } else {
        return response.json()
      }
    })
    .then(response => {
      if(response.result) {
        if(!response.response) {
          alert('프로젝트 정보가 없습니다.');
          this.setState({
            initialFlag: false,
          }, () => {
            this.loadList();
          });
        } else {
          this.setState({
            keyword: "" + response.response,
          },
          () => {
            this.loadList();
          });
        }
      }
    });
  };

  loadList = async () => {
    const {
      startDate,
      endDate,
      typeCheckList,
      keyword,
      statusCheckList,
      viewType,
      startPage,
      dateCheckList,
    } = this.state;

    this.props.openPopup({
      dialogType: 'loader',
      dialogData: {
        isShow: true,
        width: 120,
        height: 120,
      }
    });
    
    let params = '';

    let startAt = "";
    let endAt = "";

    let dateType = -1;
    if(dateCheckList[0].checked && dateCheckList[1].checked) {
      dateType = 2;
    } else if(dateCheckList[0].checked) {
      dateType = 0;
    } else if(dateCheckList[1].checked) {
      dateType = 1;
    }
    if(dateType !== -1) {
      if(startDate && endDate) {
        startAt = startDate.format('YYYY[-]MM[-]DD');
        endAt = endDate.format('YYYY[-]MM[-]DD');
        params += `projectStartAt=${startAt}&projectEndAt=${endAt}&`
        params += `dateType=${dateType}&`
      } else {
        if(!startDate && !endDate) {
          alert("기간 조건 검색시에는 프로젝트 기간을 입력해야 합니다.");
          this.props.closePopup();
          return;
        } else if(!startDate) {
          alert("검색시작일을 입력해야 합니다.")
          this.props.closePopup();
          return;
        } else if(!endDate) {
          alert("검색종료일을 입력해야 합니다.")
          this.props.closePopup();
          return;
        } else {
          alert("기간 조건 검색시에는 프로젝트 기간을 입력해야 합니다.");
          this.props.closePopup();
          return;
        }
      }
    } else {
      if(startDate !== null || endDate !== null) {
        alert("프로젝트 기간 검색시에는 기간 조건을 선택해야 합니다.");
        this.props.closePopup();
        return;
      }
    }

    if(keyword.trim() !== '') {
      params += `makerName=${keyword}&`;
    }

    // 0: 투자
    // 1: 리워드
    // 없으면 전체
    let type = -1;
    if(typeCheckList[0].checked && typeCheckList[1].checked) {
      type = "";
    } else if(typeCheckList[0].checked) {
      type = "EQUITY";
    } else if(typeCheckList[1].checked) {
      type = "FUNDING";
    } else {
      alert('프로젝트 타입 중 하나 이상 선택해야 합니다.');
      this.props.closePopup();
      return;
    }

    if(type !== -1) {
      params += `projectType=${type}&`;
    }

    let status = "";
    for(let i=0;i<statusCheckList.length;i++) {
      if(statusCheckList[i].checked) {
        status += ('' + statusCheckList[i].value + ',');
      }
    }
    if(status !== '') {
      status = status.substring(0, status.length - 1);
      params += `projectStatus=${status}&`;
    } else {
      alert('프로젝트 상태 중 하나 이상 선택해야 합니다.');
      this.props.closePopup();
      return;
    }

    if(startPage !== 1) {
      params += `startPage=${startPage}&`
    }
    params += `viewType=${viewType}`


    fetch(`${process.env.REACT_APP_RELAY_API_HOST}api/v1/wtp/makerInfo?${params}` , {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'JSESSIONID': this.props.user.token,
      }
    })
    .then(response => {
      if(response.status === 401) {
        alert('권한이 없습니다. 권한이 있는 계정으로 로그인 해주세요');
        const {
          loginUrl
         } = this.props.user
        this.props.fetchLogoutApi(this.props.user.token).then(() => {
          window.location.href = loginUrl;
        });
      } else {
        return response.json()
      }
    })
    .then(response => {
      if(response.result) {
        this.setState({
          total: response.response.makerCount,
          makerList: response.response.info,
        },
        () => {
          this.props.closePopup();
          if(this.props.projectId && this.props.projectType) {
            if(this.state.initialFlag) {
              this.setState({
                initialFlag: false,
              });
              this.props.openPopup({
                dialogType: 'maker',
                dialogData: {
                  content: {
                    maker_id: keyword,
                    project_id: this.props.projectId,
                    project_type: this.props.projectType,
                    sel_tab: 1,
                  }
                }
              });
            }
          }
        });
      } else {
        this.props.closePopup();
        alert("서버 오류가 발생하였습니다. 다시 시도해 주세요.");
      }
    });
  };

  handleTrClick = (e) => {
    const tr = e.target.closest('tr');
    const maker_id = tr.getAttribute('maker_id');
    const project_id = tr.getAttribute('project_id');
    const project_type = tr.getAttribute('project_type');
    this.props.openPopup({
      dialogType: 'maker',
      dialogData: {
        content: {
          maker_id: maker_id,
          project_id: project_id,
          project_type: project_type,
          sel_tab: 0,
        },
        closeCallback: this.loadList,
      }
    });
  };

  onChangePage = (activePage) => {
    this.setState({ startPage: activePage }, () => {
      this.loadList();
    });
  };

  onChangeDateRange = (name, value) => {
    if(name === 'startDate' && this.state.endDate) {
      const startAt = value === null ? null : value.format('YYYY[-]MM[-]DD');
      const endAt = this.state.endDate === null ? null : this.state.endDate.format('YYYY[-]MM[-]DD');
      if(endAt !== null) {
        if(startAt > endAt) {
          this.props.openPopup({
            dialogType: 'alert',
            dialogData: {
              content: "검색시작일은 검색종료일 이후로 설정할 수 없습니다.",
            }
          });
          return;
        }
      }
    }
    if(name === 'endDate' && this.state.startDate) {
      const startAt = this.state.startDate === null ? null : this.state.startDate.format('YYYY[-]MM[-]DD');
      const endAt = value === null ? null : value.format('YYYY[-]MM[-]DD');
      if(startAt !== null) {
        if(startAt > endAt) {
          this.props.openPopup({
            dialogType: 'alert',
            dialogData: {
              content: "검색종료일은 검색시작일 이전으로 설정할 수 없습니다.",
            }
          });
          return;
        }
      }
    }
    this.setState({ [name]: value });
  };

  onFocusChange = (name, value) => {
    this.setState({ [name]: value });
  };

  onChangeCheckbox = (e, idx) => {
    const { name } = e.target;
    let targetCheckList = [...this.state[name]];
    targetCheckList[idx].checked = !targetCheckList[idx].checked;

    const newState = {
      [name]: targetCheckList,
    };

    this.setState(newState);
  };

  onChangeSearchText = (e) => {
    const { value } = e.target;
    this.setState({
      keyword: value
    });
  };

  onSearch = (e) => {
    this.setState({
      startPage: 1,
    }, () => {
      this.loadList();
    });
  };

  onChangeViewType = (e) => {
    let { value } = e.target;
    value = parseInt(value, 10);
    this.setState({
      viewType: value,
      startPage: 1
    }, () => {
      this.loadList();
    });
  };

  onKeyUp = (e) => {
    if(e.keyCode === 13) {
      this.setState({
        startPage: 1,
      }, () => {
        this.loadList();
      });
    }
  }

  render() {
    const {
      makerList,
      startPage,
      total,
      viewType,
      startDate,
      startDatePickerFocus,
      endDate,
      endDatePickerFocus,
      typeCheckList,
      statusCheckList,
      dateCheckList,
      keyword,
    } = this.state;

    const {
      handleTrClick,
      onChangePage,
      onChangeDateRange,
      onFocusChange,
      onChangeCheckbox,
      onChangeSearchText,
      onSearch,
      onChangeViewType,
      onKeyUp,
    } = this;

    return (
      <MakerContent 
        handleTrClick={handleTrClick}
        makerList={makerList} 
        onChangePage={onChangePage}
        startPage={startPage}
        totalCount={total}
        viewType={viewType}
        onChangeDateRange={onChangeDateRange}
        onFocusChange={onFocusChange}
        startDate={startDate}
        startDatePickerFocus={startDatePickerFocus}
        endDate={endDate}
        endDatePickerFocus={endDatePickerFocus}
        typeCheckList={typeCheckList}
        statusCheckList={statusCheckList}
        dateCheckList={dateCheckList}
        onChangeCheckbox={onChangeCheckbox}
        keyword={keyword}
        onChangeSearchText={onChangeSearchText}
        onSearch={onSearch}
        onChangeViewType={onChangeViewType}
        onKeyUp={onKeyUp}
      />
    );
  }
}

export default connect(({ user }) => ({ user }), ({ fetchLogoutApi, openPopup, closePopup }))(MakerPage);
