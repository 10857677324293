import React, { PureComponent } from 'react';

// Plugin
import { connect } from 'react-redux';

// Components, Dialog
import HistoryContent from '../components/HistoryContent';
import { openPopup, closePopup } from '../reducers/dialogReducer'
import { fetchLogoutApi } from '../reducers/userReducer';


class HistoryPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      viewType: 10,
      startPage: 1,
      total: 0,
      historyList: [],
      prevType: "",
    };
  };

  componentDidUpdate = () => {
    const { type } = this.props;
    if(type !== this.state.prevType) {
      this.setState({
        prevType: type
      }, () => {
        this.loadList();
      });
    }
  }

  componentDidMount = () => {
    this.setState({
      prevType: this.props.type,
    }, () => {
      this.loadList();
    });
  };

  loadList = async () => {
    const {
      viewType,
      startPage,
    } = this.state;

    const {
      type
    } = this.props;

    let params = "";
    if(startPage !== 1) {
      params += `startPage=${startPage}&`;
    }
    params += `viewType=${viewType}&`;
    params += `type=${type}`;

    fetch(`${process.env.REACT_APP_RELAY_API_HOST}api/v1/wtp/setting/history?${params}` , {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'JSESSIONID': this.props.user.token,
      }
    })
    .then(response => {
      if(response.status === 401) {
        alert('권한이 없습니다. 권한이 있는 계정으로 로그인 해주세요');
        const {
          loginUrl
         } = this.props.user
        this.props.fetchLogoutApi(this.props.user.token).then(() => {
          window.location.href = loginUrl;
        });
      } else {
        return response.json()
      }
    })
    .then(response => {
      if(response.result) {
        this.setState({
          historyList: response.response.settingList,
          total: response.response.settingsCount,
        }, () => {
        });
      }
    });
  };

  onChangePage = (activePage) => {
    this.setState({ startPage: activePage }, () => {
      this.loadList();
    });
  };

  onChangeViewType = (e) => {
    let { value } = e.target;
    value = parseInt(value, 10);
    this.setState({
      viewType: value,
      startPage: 1
    }, () => {
      this.loadList();
    });
  };

  render() {
    const {
      historyList,
      total,
      viewType,
      startPage,
    } = this.state;
    const {
      onChangePage,
      onChangeViewType,
    } = this;
    let strType = '';
    if(this.props.type === 'EQUITY') {
      strType = "투자";
    } else {
      strType = "펀딩";
    }
    return (
      <HistoryContent
        type={strType}
        historyList={historyList}
        total={total}
        viewType={viewType}
        startPage={startPage}
        onChangePage={onChangePage}
        onChangeViewType={onChangeViewType}
      />
    );
  }
}

export default connect(({ user }) => ({ user }), ({ fetchLogoutApi, openPopup, closePopup }))(HistoryPage);
