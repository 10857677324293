import React from 'react';

// Plugin
import { Table } from 'react-bootstrap';

// Components
import PageLocation from '../PageLocation';
import ToggleBtn from './ToggleBtn';
import Tooltip from './Tooltip';

// CSS module
import classNames from 'classnames/bind';
import style from './SettingContent.module.scss';

const cx = classNames.bind(style);

const statusToKor = (status) => {
  if(status === 'IN_SCREENING') {
    return "심사중";
  } else if(status === 'IN_PROGRESS') {
    return "펀딩중";
  } else if(status === 'FINISHED') {
    return "펀딩 종료";
  }
}

const factorCount = (setting) => {
  let count = 0;
  for(let i=0;i<setting.length;i++) {
    const factorLen = setting[i].factorSettingList.length;
    count += factorLen;
  }
  return count;
}

const makeRows = (setting, kor, eng, onChange) => {
  let retVal = [];
  let rowSpan = factorCount(setting);
  let td1 =
    <td rowSpan={rowSpan} className={cx('rep')}>
      <span className={cx('index_kor')}>
        {kor}
        <em>{eng}</em>
      </span>
    </td>;
  for (let i = 0; i < setting.length; i++) {
    const item = setting[i];
    const factorLen = item.factorSettingList.length;
    let td2 =
      <td rowSpan={factorLen} className={cx('rep')}>{statusToKor(item.campaignStatus)}</td>;
    let name3 = item.id + "_termType";
    let td3 =
      <td rowSpan={factorLen}>
        <div className={cx('wz', 'dropdown', 'small', 'outline', 'select_box')}>
          <select className={cx('termType')} name={name3} onChange={ e => onChangeTermType(e, onChange) } defaultValue={item.termType}>
            <option value='NONE'>NONE</option>
            <option value='TIME_WINDOW'>TIME_WINDOW</option>
            <option value='AFTER_FINISH'>AFTER_FINISH</option>
          </select>
        </div>
      </td>;
    let name4 = item.id + "_timeWindowDaySize";
    let td4 =
      <td rowSpan={factorLen}>
        <input name={name4} type="text" maxLength='10' value={ item.timeWindowDaySize !== null ? item.timeWindowDaySize : 'N/A' } onChange={e=>onChangeSub(e,onChange)} disabled={item.termType !== 'TIME_WINDOW'} /> 
      </td>;
    let name5 = item.id + "_processSkipDays";
    let td5 = 
      <td rowSpan={factorLen}>
        <input name={name5} type="text" maxLength='10' value={ item.processSkipDays !== null ? item.processSkipDays : 'N/A' } onChange={e=>onChangeSub(e,onChange)} /> 
      </td>;
    for(let j=0;j<factorLen;j++) {
      const factor = item.factorSettingList[j];
      let td6 = 
        <td>{factor.name}</td>;
      let name7 = factor.id + "_weight";
      let td7 = 
        <td>
          <span className={cx('span_weight')}>
            <input name={name7} type="text" maxLength='6' value={factor.weight} onChange={e=>onChangeSub(e,onChange)} />%
          </span>
        </td>
      let name8 = factor.id + "_thresholdLower";
      let td8 =
        <td>
          <input name={name8} className={cx('items')} type="text" maxLength='10' value={factor.thresholdLower} onChange={e=>onChangeSub(e,onChange)} />
        </td>;
      let name9 = factor.id + "_thresholdUpper";
      let td9 =
        <td>
          <input name={name9} className={cx('items')} type="text" maxLength='10' value={factor.thresholdUpper} onChange={e=>onChangeSub(e,onChange)} />
        </td>;
      let name10 = factor.id + "_defaultScore";
      let td10 =
        <td>
          <input name={name10} className={cx('items')}  type="text" maxLength='10' value={factor.defaultScore} onChange={e=>onChangeSub(e,onChange)} />
        </td>;
      let toggleVal = {
        isTrue: factor.use,
      };

      let name11 = factor.id + "_use_" + factor.use;
      let td11 = 
        <td className={cx('toggle')}>
          <div>
            <ToggleBtn
              val={toggleVal}
              onChangeIsUse={ e => onChangeToggle(name11, onChange)  }
            /> 
          </div>
        </td>;
      if(i === 0 && j === 0) {
        let tr = 
          <tr key={eng+i+""+j}>
            {td1}
            {td2}
            {td3}
            {td4}
            {td5}
            {td6}
            {td7}
            {td8}
            {td9}
            {td10}
            {td11}
          </tr>;
        retVal.push(tr);
      } else if(i !== 0 && j === 0) {
        let tr =
          <tr key={eng+i+""+j}>
            {td2}
            {td3}
            {td4}
            {td5}
            {td6}
            {td7}
            {td8}
            {td9}
            {td10}
            {td11}
          </tr>;
        retVal.push(tr);
      } else {
        let tr =
          <tr key={eng+i+""+j}>
            {td6}
            {td7}
            {td8}
            {td9}
            {td10}
            {td11}
          </tr>;
        retVal.push(tr);
      }
    }
  }
  return retVal;
}

const renderItem = (settings, onChange) => {
  let retVal = [];

  const credibilityPointSetting = settings.credibilityPointSetting;
  const interactivityPointSetting = settings.interactivityPointSetting;
  const attractivenessPointSetting = settings.attractivenessPointSetting;

  let rows = makeRows(credibilityPointSetting, "평판", "credibility", onChange);
  for(let i=0;i<rows.length;i++) {
    retVal.push(rows[i]);
  }
  rows = [];
  rows = makeRows(interactivityPointSetting, "소통", "interactivity", onChange);
  for(let i=0;i<rows.length;i++) {
    retVal.push(rows[i]);
  }
  rows = [];
  rows = makeRows(attractivenessPointSetting, "인기", "attractiveness", onChange);
  for(let i=0;i<rows.length;i++) {
    retVal.push(rows[i]);
  }
  return retVal;
}

const onChangeTermType = (e, callback) => {
  const name = e.target.name;
  const sp = name.split("_");
  const param = {
    "id": Number(sp[0]),
    "target": sp[1],
    "value": e.target.value
  };
  callback(param);
}

const onChangeSub = (e, callback) => {
  const name = e.target.name;
  if(name) {
    const sp = name.split("_");
    const param = {
      "id": Number(sp[0]),
      "target": sp[1],
      "value": e.target.value,
    }
    callback(param);
  }
};

const onChangeToggle = (name, callback) => {
  if(name) {
    const sp = name.split("_");
    const param = {
      "id": Number(sp[0]),
      "target": sp[1],
      "value": sp[2] === 'true' ? false : true,
    };
    callback(param);
  }
};

const SettingContent = ({
  type,
  settings,                 // setting list
  updateAt,
  adminAccount,
  handleVerfyAndSave,       // save button handler
  user,                     // 사용자 정보,
  isSave,
  onChange,                 // onchange
}) => {
  if(settings === null) {
    return (
      <div>
      </div>
    );
  }
  const tooltip_text = "프로젝트 종료 후, 처리 종료일까지 지수를 계산한다.";
  const dateTime = updateAt.split(" ");
  const confirmationMonths = settings.confirmationMonths;
  return (
    <div>
      <PageLocation locArr={[{ loc: '신뢰지수' }, { loc: '신뢰지수 설정' }, {loc: type, active: true }]} />
      <div className={cx('title_wrapper')}>
        <div className={cx('left')}>
          <h2 className={cx('page_title')}>신뢰지수 설정</h2>
          <button className={cx('wz button gray radius', 'button')} onClick={handleVerfyAndSave} disabled={user.role < 2 || isSave} >적용</button>
        </div>
        <div className={cx('right')}>
          <div className={cx('renewal_wrapper')}>
            <h5 className={cx('page_title')}>{type} 프로젝트</h5>
            <span className={cx('bold')}>처리 종료일 <Tooltip text={tooltip_text} /> : </span>
            <span className={cx('normal')}>프로젝트 종료로 부터 </span>
            <input name='1_confirmationMonths' type='text' maxLength='4' className={cx('input_right')} value={ confirmationMonths } onChange={e => onChangeSub(e, onChange)}></input>
            <span className={cx('normal')}> 개월 까지</span>
          </div>
          <div className={cx('latest_wrapper')}>
            <span className={cx('bold')}>최종 업데이트&nbsp;&nbsp;&nbsp;[ </span>
            <span className={cx('normal')}>{`${adminAccount} / `}</span>
            <span className={cx('date_for')}>{ dateTime[0] } <em> { dateTime[1] }</em></span>
            <span className={cx('bold')}>]</span>
          </div>
        </div>
      </div>
      <div className={cx('row')}>
        <Table striped className={ cx('table') }>
          <thead>
            <tr>
              <th className={cx('td_index')}>측정지표</th>
              <th className={cx('td_type')}>단계</th>
              <th className={cx('td_index')}>시간 처리 유형</th>
              <th className={cx('td_index')}>타임 윈도우</th>
              <th className={cx('td_index')}>건너 뛸 시간</th>
              <th className={cx('td_data')}>세부 항목</th>
              <th className={cx('td_weight')}>Weight</th>
              <th className={cx('td_thresholda0')}>Threshold (A0)</th>
              <th className={cx('td_thresholda1')}>Threshold (A1)</th>
              <th className={cx('td_enabled')}>Default 점수</th>
              <th className={cx('td_enabled')}>반영</th>
            </tr>
          </thead>
          <tbody>
            { renderItem(settings, onChange) }
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default SettingContent;
