import React, { PureComponent } from 'react';

// Plugin
import { connect } from 'react-redux';

// Components, Dialog
import SettingContent from '../components/SettingContent';
import { openPopup, closePopup } from '../reducers/dialogReducer'
import { fetchLogoutApi } from '../reducers/userReducer';

class SettingPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      updateAt: "",
      adminAccount: "",
      prevSetting: null,
      setting: null,
      prevType: "",
    };
  }

  componentDidUpdate = () => {
    const { type } = this.props;
    if(type !== this.state.prevType) {
      this.setState({
        prevType: type,
        prevSetting: null,
        setting: null,
      }, () => {
        this.loadSetting();
      });
    }
  }

  componentDidMount = () => {
    this.setState({
      prevType: this.props.type,
      prevSetting: null,
      setting: null,
    });
    this.loadSetting();
  }

  loadSetting = async () => {
    const { type } = this.props;
    fetch(`${process.env.REACT_APP_RELAY_API_HOST}api/v1/wtp/setting?type=` + type , {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'JSESSIONID': this.props.user.token,
      }
    })
    .then(response => {
      if(response.status === 401) {
        alert('권한이 없습니다. 권한이 있는 계정으로 로그인 해주세요');
        const {
          loginUrl
         } = this.props.user
        this.props.fetchLogoutApi(this.props.user.token).then(() => {
          window.location.href = loginUrl;
        });
      } else {
        return response.json()
      }
    })
    .then(response => {
      if(response.result) {
        let resp = response.response;
        let updateAt = resp.update_at;
        let adminAccount = resp.admin_account;
        let settingJson = resp.setting_json;
        let setting = JSON.parse(settingJson);
        setting['id'] = 1;
        for(let i=0;i<setting.credibilityPointSetting.length;i++) {
          setting.credibilityPointSetting[i]['id'] = 10 + i;
          let item = setting.credibilityPointSetting[i];
          for(let j=0;j<item.factorSettingList.length;j++) {
            let factor = item.factorSettingList[j];
            factor.weight = factor.weight * 100.0;
          }
        }
        for(let i=0;i<setting.interactivityPointSetting.length;i++) {
          setting.interactivityPointSetting[i]['id'] = 20 + i;
          let item = setting.interactivityPointSetting[i];
          for(let j=0;j<item.factorSettingList.length;j++) {
            let factor = item.factorSettingList[j];
            factor.weight = factor.weight * 100.0;
          }
        }
        for(let i=0;i<setting.attractivenessPointSetting.length;i++) {
          setting.attractivenessPointSetting[i]['id'] = 30 + i;
          let item = setting.attractivenessPointSetting[i];
          for(let j=0;j<item.factorSettingList.length;j++) {
            let factor = item.factorSettingList[j];
            factor.weight = factor.weight * 100.0;
          }
        }
        // id setting
        let prevSetting = JSON.parse(JSON.stringify(setting));
        this.setState({
          updateAt: updateAt,
          adminAccount: adminAccount,
          prevSetting: prevSetting,
          setting: setting,
        });
      }
    });
  };

  handleVerfyAndSave = (e) => {
    e.target.blur();
    this.props.openPopup({
      dialogType: 'loader',
      dialogData: {
        isShow: true,
      }
    });

    const creditList = [
      'AAA', 'AA+', 'AA', 'AA-', 'A+', 'A', 'A-', 'BBB+', 'BBB', 'BBB-', 'BB+', 'BB', 'BB-', 'B+', 'B', 'B-', 'CCC', 'CC', 'C', 'D', 'R'
    ]

    let setting = JSON.parse(JSON.stringify(this.state.setting));

    // number convert / verify
    if(isNaN(parseInt(setting.confirmationMonths)) || parseInt(setting.confirmationMonths) < 0) {
      this.props.openPopup({
        dialogType: 'alert',
        dialogData: {
          content: '처리 종료일은 0개월 이상이어야 합니다.',
        }
      });
      return;
    }

    let cred = setting.credibilityPointSetting;
    let int = setting.interactivityPointSetting;
    let attr = setting.attractivenessPointSetting;

    for(let i=0;i<cred.length;i++) {
      let item = cred[i];
      let strStatus = "";
      if(item.campaignStatus === "IN_SCREENING") {
        strStatus = "심사중";
      } else if(item.campaignStatus === "IN_PROGRESS") {
        strStatus = "펀딩중";
      } else if(item.campaignStatus === "FINISHED") {
        strStatus = "펀딩 종료";
      }
      if(item.termType === 'TIME_WINDOW') {
        if(isNaN(parseInt(item.timeWindowDaySize)) || parseInt(item.timeWindowDaySize) < 0) {
          this.props.openPopup({
            dialogType: 'alert',
            dialogData: {
              content: `평판 - ${strStatus} : 타임윈도우 값은 0일 이상이어야 합니다.`,
            }
          });
          return;
        }
      }
      if(isNaN(parseInt(item.processSkipDays)) || parseInt(item.processSkipDays) < 0) {
        this.props.openPopup({
          dialogType: 'alert',
          dialogData: {
            content: `평판 - ${strStatus} : 건너 뛸 시간은 0 이상이어야 합니다.`,
          }
        });
        return;
      } else {
        item.processSkipDays = parseInt(item.processSkipDays);
      }
      let weight_sum = 0;
      for(let j=0;j<item.factorSettingList.length;j++) {
        let factor = item.factorSettingList[j];
        if(factor.targetType === 'creditRating') {
          if(isNaN(parseFloat(factor.weight)) || parseFloat(factor.weight) < 0) {
            this.props.openPopup({
              dialogType: 'alert',
              dialogData: {
                content: `평판 - ${strStatus} - ${factor.name} : weight는 0 이상이어야 합니다.`,
              }
            });
            return;
          } else {
            factor.weight = parseFloat(factor.weight)
          }
          if(creditList.indexOf(factor.thresholdLower) === -1 || creditList.indexOf(factor.thresholdUpper) === -1) {
            this.props.openPopup({
              dialogType: 'alert',
              dialogData: {
                content: `신용등급의 threshold는 ${creditList}중 하나의 값이어야 합니다.`,
              }
            });
            return;
          }
          if(creditList.indexOf(factor.thresholdLower) !== -1 && creditList.indexOf(factor.thresholdUpper) !== -1) {
            if(creditList.indexOf(factor.thresholdLower) <= creditList.indexOf(factor.thresholdUpper)) {
              this.props.openPopup({
                dialogType: 'alert',
                dialogData: {
                  content: `평판 - ${strStatus} - ${factor.name} : threshold(A0)는 threshold(A1)보다 작아야 합니다.`,
                }
              });
              return; 
            }
          }
          if(isNaN(parseFloat(factor.defaultScore))) {
            this.props.openPopup({
              dialogType: 'alert',
              dialogData: {
                content: `평판 - ${strStatus} - ${factor.name} : default 점수는 숫자여야 합니다.`,
              }
            });
            return;
          } else {
            factor.defaultScore = parseFloat(factor.defaultScore)
          }
        } else {
          if(isNaN(parseFloat(factor.weight)) || parseFloat(factor.weight) < 0) {
            this.props.openPopup({
              dialogType: 'alert',
              dialogData: {
                content: `평판 - ${strStatus} - ${factor.name} : weight는 0 이상이어야 합니다.`,
              }
            });
            return;
          } else {
            factor.weight = parseFloat(factor.weight)
          } 
          if(isNaN(parseFloat(factor.thresholdLower))) {
            this.props.openPopup({
              dialogType: 'alert',
              dialogData: {
                content: `평판 - ${strStatus} - ${factor.name} : threshold(A0)는 숫자여야 합니다.`,
              }
            });
            return;
          } else {
            factor.thresholdLower = parseFloat(factor.thresholdLower)
          } 
          if(isNaN(parseFloat(factor.thresholdUpper))) {
            this.props.openPopup({
              dialogType: 'alert',
              dialogData: {
                content: `평판 - ${strStatus} - ${factor.name} : threshold(A1)는 숫자여야 합니다.`,
              }
            });
            return;
          } else {
            factor.thresholdUpper = parseFloat(factor.thresholdUpper)
          }
          if(factor.thresholdLower >= factor.thresholdUpper) {
            this.props.openPopup({
              dialogType: 'alert',
              dialogData: {
                content: `평판 - ${strStatus} - ${factor.name} : threshold(A0)는 threshold(A1)보다 작아야 합니다.`,
              }
            });
            return;
          }
          if(isNaN(parseFloat(factor.defaultScore))) {
            this.props.openPopup({
              dialogType: 'alert',
              dialogData: {
                content: `평판 - ${strStatus} - ${factor.name} : default 점수는 숫자여야 합니다.`,
              }
            });
            return;
          } else {
            factor.defaultScore = parseFloat(factor.defaultScore)
          }
        }
        if(factor.use) {
          weight_sum += factor.weight;
        }
      }
      if(weight_sum !== 100) {
        this.props.openPopup({
          dialogType: 'alert',
          dialogData: {
            content: `평판 - ${strStatus} : weight합은 100%이어야 합니다.`,
          }
        });
        return; 
      }
      delete cred[i].id;
    }

    for(let i=0;i<int.length;i++) {
      let item = int[i];
      let strStatus = "";
      if(item.campaignStatus === "IN_SCREENING") {
        strStatus = "심사중";
      } else if(item.campaignStatus === "IN_PROGRESS") {
        strStatus = "펀딩중";
      } else if(item.campaignStatus === "FINISHED") {
        strStatus = "펀딩 종료";
      }
      if(item.termType === 'TIME_WINDOW') {
        if(isNaN(parseInt(item.timeWindowDaySize)) || parseInt(item.timeWindowDaySize) < 0) {
          this.props.openPopup({
            dialogType: 'alert',
            dialogData: {
              content: `소통 - ${strStatus} : 타임윈도우 값은 0일 이상이어야 합니다.`,
            }
          });
          return;
        }
      }
      if(isNaN(parseInt(item.processSkipDays)) || parseInt(item.processSkipDays) < 0) {
        this.props.openPopup({
          dialogType: 'alert',
          dialogData: {
            content: `소통 - ${strStatus} : 건너 뛸 시간은 0 이상이어야 합니다.`,
          }
        });
        return;
      } else {
        item.processSkipDays = parseInt(item.processSkipDays);
      }
      let weight_sum = 0;
      for(let j=0;j<item.factorSettingList.length;j++) {
        let factor = item.factorSettingList[j];
        if(isNaN(parseFloat(factor.weight)) || parseFloat(factor.weight) < 0) {
          this.props.openPopup({
            dialogType: 'alert',
            dialogData: {
              content: `소통 - ${strStatus} - ${factor.name} : weight는 0 이상이어야 합니다.`,
            }
          });
          return;
        } else {
          factor.weight = parseFloat(factor.weight)
        } 
        if(isNaN(parseFloat(factor.thresholdLower))) {
          this.props.openPopup({
            dialogType: 'alert',
            dialogData: {
              content: `소통 - ${strStatus} - ${factor.name} : threshold(A0)는 숫자여야 합니다.`,
            }
          });
          return;
        } else {
          factor.thresholdLower = parseFloat(factor.thresholdLower)
        } 
        if(isNaN(parseFloat(factor.thresholdUpper))) {
          this.props.openPopup({
            dialogType: 'alert',
            dialogData: {
              content: `소통 - ${strStatus} - ${factor.name} : threshold(A1)는 숫자여야 합니다.`,
            }
          });
          return;
        } else {
          factor.thresholdUpper = parseFloat(factor.thresholdUpper)
        }
        if(factor.thresholdLower >= factor.thresholdUpper) {
          this.props.openPopup({
            dialogType: 'alert',
            dialogData: {
              content: `소통 - ${strStatus} - ${factor.name} : threshold(A0)는 threshold(A1)보다 작아야 합니다.`,
            }
          });
          return;
        }
        if(isNaN(parseFloat(factor.defaultScore))) {
          this.props.openPopup({
            dialogType: 'alert',
            dialogData: {
              content: `소통 - ${strStatus} - ${factor.name} : default 점수는 숫자여야 합니다.`,
            }
          });
          return;
        } else {
          factor.defaultScore = parseFloat(factor.defaultScore)
        }
        if(factor.use) {
          weight_sum += factor.weight;
        }
      }
      if(weight_sum !== 100) {
        this.props.openPopup({
          dialogType: 'alert',
          dialogData: {
            content: `소통 - ${strStatus} : weight합은 100%이어야 합니다.`,
          }
        });
        return; 
      }
      delete int[i].id;
    }
    for(let i=0;i<attr.length;i++) {
      let item = attr[i];
      let strStatus = "";
      if(item.campaignStatus === "IN_SCREENING") {
        strStatus = "심사중";
      } else if(item.campaignStatus === "IN_PROGRESS") {
        strStatus = "펀딩중";
      } else if(item.campaignStatus === "FINISHED") {
        strStatus = "펀딩 종료";
      }
      if(item.termType === 'TIME_WINDOW') {
        if(isNaN(parseInt(item.timeWindowDaySize)) || parseInt(item.timeWindowDaySize) < 0) {
          this.props.openPopup({
            dialogType: 'alert',
            dialogData: {
              content: `인기 - ${strStatus} : 타임윈도우 값은 0일 이상이어야 합니다.`,
            }
          });
          return;
        }
      }
      if(isNaN(parseInt(item.processSkipDays)) || parseInt(item.processSkipDays) < 0) {
        this.props.openPopup({
          dialogType: 'alert',
          dialogData: {
            content: `인기 - ${strStatus} : 건너 뛸 시간은 0 이상이어야 합니다.`,
          }
        });
        return;
      } else {
        item.processSkipDays = parseInt(item.processSkipDays);
      }
      let weight_sum = 0;
      for(let j=0;j<item.factorSettingList.length;j++) {
        let factor = item.factorSettingList[j];
        if(isNaN(parseFloat(factor.weight)) || parseFloat(factor.weight) < 0) {
          this.props.openPopup({
            dialogType: 'alert',
            dialogData: {
              content: `인기 - ${strStatus} - ${factor.name} : weight는 0 이상이어야 합니다.`,
            }
          });
          return;
        } else {
          factor.weight = parseFloat(factor.weight)
        } 
        if(isNaN(parseFloat(factor.thresholdLower))) {
          this.props.openPopup({
            dialogType: 'alert',
            dialogData: {
              content: `인기 - ${strStatus} - ${factor.name} : threshold(A0)는 숫자여야 합니다.`,
            }
          });
          return;
        } else {
          factor.thresholdLower = parseFloat(factor.thresholdLower)
        } 
        if(isNaN(parseFloat(factor.thresholdUpper))) {
          this.props.openPopup({
            dialogType: 'alert',
            dialogData: {
              content: `인기 - ${strStatus} - ${factor.name} : threshold(A1)는 숫자여야 합니다.`,
            }
          });
          return;
        } else {
          factor.thresholdUpper = parseFloat(factor.thresholdUpper)
        }
        if(factor.thresholdLower >= factor.thresholdUpper) {
          this.props.openPopup({
            dialogType: 'alert',
            dialogData: {
              content: `인기 - ${strStatus} - ${factor.name} : threshold(A0)는 threshold(A1)보다 작아야 합니다.`,
            }
          });
          return;
        }
        if(isNaN(parseFloat(factor.defaultScore))) {
          this.props.openPopup({
            dialogType: 'alert',
            dialogData: {
              content: `인기 - ${strStatus} - ${factor.name} : default 점수는 숫자여야 합니다.`,
            }
          });
          return;
        } else {
          factor.defaultScore = parseFloat(factor.defaultScore)
        }
        if(factor.use) {
          weight_sum += factor.weight;
        }
      }
      if(weight_sum !== 100) {
        this.props.openPopup({
          dialogType: 'alert',
          dialogData: {
            content: `인기 - ${strStatus} : weight합은 100%이어야 합니다.`,
          }
        });
        return; 
      }
      delete attr[i].id;
    }
    delete setting.id;

    for(let i=0;i<cred.length;i++) {
      let item = cred[i];
      for(let j=0;j<item.factorSettingList.length;j++) {
        let factor = item.factorSettingList[j];
        factor.weight = factor.weight / 100.0;
      }
    }
    for(let i=0;i<int.length;i++) {
      let item = int[i];
      for(let j=0;j<item.factorSettingList.length;j++) {
        let factor = item.factorSettingList[j];
        factor.weight = factor.weight / 100.0;
      }
    }
    for(let i=0;i<attr.length;i++) {
      let item = attr[i];
      for(let j=0;j<item.factorSettingList.length;j++) {
        let factor = item.factorSettingList[j];
        factor.weight = factor.weight / 100.0;
      }
    }

    let body = {
      campaignType: this.props.type,
      summary: this.props.user.id + "_" + new Date().getTime(),
      settingJson: JSON.stringify(setting),
      adminAccount: this.props.user.id
    };

    fetch(`${process.env.REACT_APP_RELAY_API_HOST}api/v1/wtp/updateSetting` , {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'JSESSIONID': this.props.user.token,
      },
      body: JSON.stringify(body)
    })
    .then(response => {
      if(response.status === 401) {
        alert('권한이 없습니다. 권한이 있는 계정으로 로그인 해주세요');
        const {
          loginUrl
         } = this.props.user
        this.props.fetchLogoutApi(this.props.user.token).then(() => {
          window.location.href = loginUrl;
        });
      } else {
        return response.json()
      }
    })
    .then(response => {
      this.props.closePopup();
      if(response.result) {
        this.props.openPopup({
          dialogType: 'alert',
          dialogData: {
            content: "설정이 저장되었습니다.",
            closeCallback: this.loadSetting,
          }
        }); 
      }
    });
  }

  onChange = (json) => {
    let new_setting = JSON.parse(JSON.stringify(this.state.setting));
    if(json.id >= 100) {
      if(json.id < 200) {
        let cred = new_setting.credibilityPointSetting;
        for(let i=0;i<cred.length;i++) {
          let factors = cred[i].factorSettingList;
          let flag = false;
          for(let j=0;j<factors.length;j++) {
            let factor = factors[j];
            if(factor.id === json.id) {
              factor[json.target] = json.value;
              flag = true;
              break;
            }
          }
          if(flag) {
            break;
          }
        }
      } else if(json.id < 300) {
        let int = new_setting.interactivityPointSetting;
        for(let i=0;i<int.length;i++) {
          let factors = int[i].factorSettingList;
          let flag = false;
          for(let j=0;j<factors.length;j++) {
            let factor = factors[j];
            if(factor.id === json.id) {
              factor[json.target] = json.value;
              flag = true;
              break;
            }
          }
          if(flag) {
            break;
          }
        }
      } else if(json.id < 400) {
        let attr = new_setting.attractivenessPointSetting;
        for(let i=0;i<attr.length;i++) {
          let factors = attr[i].factorSettingList;
          let flag = false;
          for(let j=0;j<factors.length;j++) {
            let factor = factors[j];
            if(factor.id === json.id) {
              factor[json.target] = json.value;
              flag = true;
              break;
            }
          }
          if(flag) {
            break;
          }
        }
      }
    } else if(json.id >= 10) {
      if(json.id < 20) {
        let cred = new_setting.credibilityPointSetting;
        cred[json.id - 10][json.target] = json.value;
        if(json.target === 'termType') {
          if(json.value !== 'TIME_WINDOW') {
            cred[json.id - 10]['timeWindowDaySize'] = null;
          } else {
            cred[json.id - 10]['timeWindowDaySize'] = 1;
          }
        }
      } else if(json.id < 30) {
        let int = new_setting.interactivityPointSetting;
        int[json.id - 20][json.target] = json.value;
        if(json.target === 'termType') {
          if(json.value !== 'TIME_WINDOW') {
            int[json.id - 20]['timeWindowDaySize'] = null;
          } else {
            int[json.id - 20]['timeWindowDaySize'] = 1;
          }
        }
      } else if(json.id < 40) {
        let attr = new_setting.attractivenessPointSetting;
        attr[json.id - 30][json.target] = json.value;
        if(json.target === 'termType') {
          if(json.value !== 'TIME_WINDOW') {
            attr[json.id - 30]['timeWindowDaySize'] = null;
          } else {
            attr[json.id - 30]['timeWindowDaySize'] = 1;
          }
        }
      }
    } else if(json.id === 1) {
      new_setting[json.target] = json.value;
    }
    this.setState({
      setting: new_setting,
    });
  }

  render() {
    const { setting, updateAt, adminAccount } = this.state;
    const { handleVerfyAndSave, onChange } = this;
    const { type } = this.props;
    let strType = "";
    if(type === 'FUNDING') {
      strType = "펀딩";
    } else {
      strType = "투자";
    }

    return (
      <SettingContent
        type={strType}
        settings={ setting }
        updateAt={updateAt}
        adminAccount={adminAccount}
        handleVerfyAndSave={ handleVerfyAndSave }
        user={ this.props.user }
        isSave={ JSON.stringify(this.state.setting) === JSON.stringify(this.state.prevSetting) }
        onChange={onChange}
      />
    );
  }
}

export default connect(({ user }) => ({ user }), ({ fetchLogoutApi, openPopup, closePopup }))(SettingPage);
