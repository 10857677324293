import React from 'react';
import AlertDialog from './AlertDialog';
import MakerDialog from './MakerDialog';
import LoaderDialog from './LoaderDialog';

const Dialog = () => {
  return (
    <>
      <AlertDialog />
      <MakerDialog />
      <LoaderDialog />
    </>
  );
};

export default Dialog;
